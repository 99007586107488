import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from 'classnames/bind';
import { Centered, Loader, statusMap } from "../Sugar";
import ChoiceWidget from "../ChoiceWidget";
import styles from './styles.module.css';
import API from "../../api";
import { useAlerts, useNotification, useTitle } from "../../hooks";
import { useTranslation } from "react-i18next";
import SectionBox from "../SectionBox";
import { TextInput } from "../Inputs";
import DeviceInputsChart from './DeviceInputsChart';
import {StatusTimeline, StatusTimelineLegend} from '../StatusTimeline';
import { phaseCurrentColors, analogInputColors } from "../Plot";


interface IDevice {
  serial: string,
  expanded?: boolean,
  partOfFleets?: {
    name: string,
    publicId: string
  }[]
}

interface DeviceEntryProps {
  entry: IDevice,
  onDropdownClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

interface DeviceGridProps {
  devices: IDevice[],
  expandedIdx: number | null,
  toggleCallback: (index: number) => void,
  scroll: boolean
}

interface DeviceEntryDetailsProps {
  forEntry: IDevice,
  gridPosition: {
    x: number,
    y: number
  },
  scroll: boolean
}

interface FleetInformationProps {
  fleets: {
    name: string,
    publicId: string
  }[]
}

type InputDataResp = Record<string, {time: string, value: number}[]>;

interface DeviceInputDataResp {
  inputData: InputDataResp,
  start: string,
  end: string,
}

export interface Point {
  time: Date,
  value: number,
}

export interface InputData {
  name: string,
  points: Point[],
}

interface DeviceInputData {
  ctInputData: InputData[],
  analogInputData: InputData[],
  digitalInputData: InputData[],
  start: Date,
  end: Date,
}

const parseDataWithPrefix = (inputData: InputDataResp, prefix: string): InputData[] => {
  return Object.entries(inputData).flatMap(([inputName, points]) => (
    inputName.startsWith(prefix) ? [{name: inputName, points: points.map(p => ({time: new Date(p.time), value: p.value}))}] : []
  ));
};

const parseDeviceInputData = (respData: DeviceInputDataResp): DeviceInputData => {
  return {
    ctInputData: parseDataWithPrefix(respData.inputData, 'CT'),
    analogInputData: parseDataWithPrefix(respData.inputData, 'CL'),
    digitalInputData: parseDataWithPrefix(respData.inputData, 'DI'),
    start: new Date(respData.start),
    end: new Date(respData.end),
  };
};


const DeviceGrid = ({ devices, expandedIdx, toggleCallback, scroll }: DeviceGridProps): JSX.Element => {
  const [numColumns, setNumColumns] = useState<number | null>(null);

  const colMinWidth = 300;
  let gridContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let resizeCallbackTimer: any = null;
    const handleWidthUpdate = () => {
      if (resizeCallbackTimer === null) {
        resizeCallbackTimer = setTimeout(() => {
          updateWidthNow();
        }, 50);
      }
    }
  
    const updateWidthNow = () => {
      if (gridContainer.current) {
        const width = gridContainer.current.offsetWidth;
        const numColumns = Math.max(1, Math.floor(width / colMinWidth));
        setNumColumns(numColumns);
      }
      clearTimeout(resizeCallbackTimer);
      resizeCallbackTimer = null;
    }
    window.addEventListener('resize', handleWidthUpdate);
    updateWidthNow();
    return () => {
      window.removeEventListener('resize', handleWidthUpdate);
    }
  }, [])

  let maybeEntryDetails: JSX.Element | null = null;
  if (expandedIdx !== null && numColumns !== null) {
    const forEntry = devices[expandedIdx];
    // Calculate the XY position in the grid where the details should open. (1 row below the entry
      // and to either side.
    let entryPosx = expandedIdx % numColumns;
    if (entryPosx >= numColumns - 1) {
      entryPosx--;
    }
    const entryPosy = Math.floor(expandedIdx / numColumns);
    const detailsGridPosition = { // 1-based indexing in grid
      x: entryPosx + 1,
      y: entryPosy + 2,
    }
    maybeEntryDetails = <DeviceEntryDetails key={forEntry.serial} forEntry={forEntry} gridPosition={detailsGridPosition} scroll={scroll} />
  }

  return (
    <div ref={gridContainer} style={{
      display: 'grid',
      gridTemplateColumns: `repeat(${numColumns}, minmax(${colMinWidth}px, 1fr))`,
    }}>
      {
        devices.map((entry: IDevice, i: number) => (
          <DeviceEntry key={entry.serial} entry={entry} onDropdownClick={() => { toggleCallback(i) }} />
        ))
      }
      { maybeEntryDetails }
    </div>
  );
}

const DeviceEntry = ({ entry, onDropdownClick }: DeviceEntryProps) => {
  return (
    <div className={classNames( styles.deviceEntry, { [styles.expanded]: entry.expanded })}>
      <div className={styles.header}>
        <div className={styles.name}>{ entry.serial }</div>
        <button className={styles.dropdown} onClick={(event: React.MouseEvent<HTMLButtonElement>) => onDropdownClick(event)} />
      </div>
    </div>
  );
}

const FleetInformation = ({ fleets }: FleetInformationProps) => {
  const { alerts } = useAlerts();
  const navigate = useNavigate();

  const fleetId = fleets[fleets.length-1] ? fleets[fleets.length-1].publicId : null;
  const status = fleetId && alerts && alerts.findIndex(alert => alert.forFleetPublicId === fleetId) > -1 ? 'DANGER' : 'OK';
  const statusClass = statusMap[status].className;

  return (
    <div className={styles.deviceInputDetails}>
      <div className={classNames(styles.status, statusClass)}>
        {statusMap[status].text}
      </div>
      { fleets.map(item => (
        <div className={styles.name} key={item.publicId}>
          { item.name }
        </div>
      ))}
      <button className={styles.drilldown} onClick={() => fleetId && navigate(`/station/${fleetId}`) } />
    </div> 
  )
}

const DeviceEntryDetails = ({ forEntry, gridPosition, scroll }: DeviceEntryDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const { loadingAlerts } = useAlerts();
  const [loading, setLoading] = useState<boolean>(true);
  const [deviceInputData, setDeviceInputData] = useState<DeviceInputData | null>(null);

  useEffect(() => {
    let mounted = true;
    let timer: ReturnType<typeof setTimeout> | null = null;
    const aborter = new AbortController();

    const fetchData = (serial: string) => {
      API.getDeviceData(forEntry.serial, aborter.signal).then((respData: DeviceInputDataResp) => {
        if (mounted) {
          setDeviceInputData(parseDeviceInputData(respData));
          timer = setTimeout(() => {fetchData(serial)}, 2000);
        }
      }).catch((err) => {
        if (err.code !== 'ERR_CANCELED') {
          console.log(err);
        }
      }).finally(() => {
        mounted && setLoading(false);
      })
    };
    fetchData(forEntry.serial);

    return () => {
      timer && clearTimeout(timer);
      aborter.abort();
      mounted = false;
    };
  }, [forEntry.serial]);

  useEffect(() => {
    if (ref.current && scroll) {
      ref.current.scrollIntoView({ block: 'start' });
    }
  }, [ref, scroll])

  const gridColumnCSS = `${gridPosition.x} / ${gridPosition.x + 2}`
  const gridRowCSS = `${gridPosition.y} / ${gridPosition.y + 9}` // TODO.

  return (
    <div 
      className={styles.deviceInputWrapper} 
      style={{
        gridColumn: gridColumnCSS,
        gridRow: gridRowCSS,
      }}
      ref={ref}
    >
      { (loading || loadingAlerts) 
        ? 
          <Loader /> 
        : 
          <>
            { forEntry.partOfFleets && <FleetInformation fleets={forEntry.partOfFleets} /> }
            {deviceInputData && <>
              <DeviceInputsChart
                range={[deviceInputData.start, deviceInputData.end]}
                inputDataArray={deviceInputData.ctInputData}
                yTitle={`${t('phase_current')} (A)`}
                colors={phaseCurrentColors}
              />
              <DeviceInputsChart
                range={[deviceInputData.start, deviceInputData.end]}
                inputDataArray={deviceInputData.analogInputData}
                yTitle={`${t('analog_input')} (mA)`}
                colors={analogInputColors}
              />
              {deviceInputData.digitalInputData.map(inputData => (
                <StatusTimeline key={inputData.name} range={[deviceInputData.start, deviceInputData.end]} inputData={inputData}/>
              ))}
              <StatusTimelineLegend />
            </>}
          </>
      }
    </div>
  );
}

const Devices = () => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const navigate = useNavigate();
  useTitle(t('devices'));
  const { serial } = useParams();
  const [installed, setInstalled] = useState<IDevice[]>([]);
  const [available, setAvailable] = useState<IDevice[]>([]);
  const [view, setView] = useState<string>('available');
  const [expandedIdx, setExpandedIdx] = useState<number | null>(null);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    if (isInitialLoading) {
      API.getDevices().then(data => {
        if (serial) {
          const installedIndex = data.installed.findIndex((item: IDevice) => item.serial === serial);
          const availableIndex = data.available.findIndex((item: IDevice) => item.serial === serial);
          if (installedIndex > -1) {
            const devices = data.installed.map((item: IDevice) => {
              return ({
                ...item,
                expanded: item.serial === serial,
              })
            })
            setView('installed');
            setInstalled(devices);
            setAvailable(data.available);
            setExpandedIdx(installedIndex);
          } else if (availableIndex > -1) {
            const devices = data.available.map((item: IDevice) => {
              return ({
                ...item,
                expanded: item.serial === serial,
              })
            })
            setView('available');
            setInstalled(data.installed);
            setAvailable(devices);
            setExpandedIdx(availableIndex);
          } else {
            setAvailable(data.available);
            setInstalled(data.installed);
            setNotification(t('device_not_found', { serial: serial }), 'DANGER');
          }
        } else {
          setAvailable(data.available);
          setInstalled(data.installed);
        }
      }).finally(() => {
        setIsInitialLoading(false);
      })
    }
  }, [serial, isInitialLoading, setNotification, t])

  const currentDevices = view === 'available' ? available : installed;
  const filteredDevices = currentDevices.filter((device: IDevice) => device.serial.toLowerCase().includes(filter.toLowerCase()));

  const handleEntryToggle = (deviceEntryIdx: number) => {
    let newExpandedIdx = expandedIdx;
    let devices: IDevice[] = currentDevices;
    if (expandedIdx === null) {
      // Just open new
      filteredDevices[deviceEntryIdx].expanded = true;
      newExpandedIdx = deviceEntryIdx;
      navigate(`/devices/${filteredDevices[deviceEntryIdx].serial}`);
    } else {
      // close old.
      filteredDevices[expandedIdx].expanded = false;
      navigate(`/devices`);
      // Are we just closing?
      if (deviceEntryIdx === expandedIdx) {
        newExpandedIdx = null;
      } else {
        filteredDevices[deviceEntryIdx].expanded = true;
        newExpandedIdx = deviceEntryIdx;
        navigate(`/devices/${filteredDevices[deviceEntryIdx].serial}`);
      }
    }
    view === 'available' ? setAvailable(devices) : setInstalled(devices);
    setExpandedIdx(newExpandedIdx);
  }

  const handleDevicesView = (event: React.FormEvent<HTMLInputElement>) => {
    const eventTarget = event.target as HTMLInputElement;
    const view = eventTarget.value;
    setView(view);
    if (expandedIdx || expandedIdx === 0) {
      handleEntryToggle(expandedIdx);
    }
  }

  const handleDevicesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (expandedIdx || expandedIdx === 0) {
      handleEntryToggle(expandedIdx);
    }
    const value = event.target.value;
    setFilter(value);
  }

  return ( 
    <>
      <h1>{t('devices')}</h1>
      <div style={{display: 'flex'}}>
        <fieldset style={{ margin: 0, padding: 10, border: 0 }}>
        <legend>{t('show')}</legend>
          <ChoiceWidget
            choices={[{value: 'available', title: t('available')}, {value: 'installed', title: t('installed')}]}
            name={'status-select'}
            selected={view}
            onInput={handleDevicesView}
          />
        </fieldset>
        <fieldset style={{ margin: '0 0 0 auto', padding: 10, border: 0 }}>
          <legend>{t('filter')}</legend>
          <TextInput onChange={(e) => handleDevicesFilter(e)} value={filter} placeholder={`${t('eg')} VT03`} />
        </fieldset>
      </div>
      { isInitialLoading && <Loader text={t('loading_devices')} /> }
      { !isInitialLoading && filteredDevices.length === 0 && <SectionBox><Centered><p>{t('no_devices_found')}</p></Centered></SectionBox> }
      { <DeviceGrid 
          devices={filteredDevices} 
          expandedIdx={expandedIdx} 
          toggleCallback={handleEntryToggle}
          scroll={isInitialLoading}
        /> 
      }
    </>
  );
}

export default Devices;