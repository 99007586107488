import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";
import L, { latLngBounds, LatLngLiteral, LeafletMouseEvent, LocationEvent } from 'leaflet'
import { GestureHandling } from "leaflet-gesture-handling";
import markerOk from '../../icons/marker-ok.png';
import markerDanger from '../../icons/marker-danger.png';
import { Status } from "../../types";
import { statusMap } from "../Sugar";
import styles from './styles.module.css';
import { useTranslation } from "react-i18next";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import './leaflet.css';

export interface IMarker {
  coordinates: LatLngLiteral,
  partOfFleets: string[],
  status: Status,
  publicId: string,
  navigation?: boolean
}

interface MapProps {
  markers?: IMarker[],
  height?: string,
  locationHandler?: (coords: LatLngLiteral) => void,
  resetLocation?: () => void,
  location?: [number, number] | null
}

interface LocationControlProps {
  locationHandler: (coords: LatLngLiteral) => void,
  resetLocation: () => void
}

const Map = ({ markers, height, locationHandler, resetLocation, location }: MapProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const bounds = useMemo(() => {
    let b = latLngBounds([]);
    const borders = [{lat: 70.13188844102397, lng: 27.905273437500004}, {lat: 59.54240738003436, lng: 23.071289062500004}];
    if (markers) {
      markers.forEach((marker) => {
        b.extend(marker.coordinates);
      })
    } else {
      borders.forEach((border) => {
        b.extend(border);
      })
    }
    return b
  }, [markers])

  const center = useMemo(() => {
    return markers && markers.length === 1 ? markers[0].coordinates : undefined
  }, [markers])

  const getMarkerIcon = (status: Status) => {
    return new L.Icon({
      iconUrl: status === 'DANGER' ? markerDanger : markerOk,
      iconSize: [40, 60],
      iconAnchor: [22, 60],
      popupAnchor: [-3, -60],
      className: status === 'DANGER' ? 'danger' : ''
    });
  }

  const handleNavigate = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    navigate(`/station/${id}`);
  }

  const GestureHandlingSetter = () => {
    const map = useMap() as any;
    map.gestureHandling.enable();
    map.addEventListener("gestureHandling", GestureHandling, { passive: false });
    return null;
  };

  const LocationMarker = ({ handlePosition, location }: any) => {
    const map = useMapEvents({
      click(e: LeafletMouseEvent) {
        const type = (e.originalEvent.target as any).localName;
        if (type !== 'button') {
          handlePosition(e.latlng);
          map.dragging.enable();
        }
      }
    })
  
    return location && location.length > 1 ? <Marker position={location} /> : null
  }

  const LocationControl = ({ locationHandler, resetLocation }: LocationControlProps) => {
    const map = useMap();
    const handleLocation = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      map.locate().on("locationfound", (e: LocationEvent) => {
        locationHandler(e.latlng);
        map.flyTo(e.latlng, 14);
        map.dragging.enable();
      })
    }
  
    const handleReset = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      resetLocation();
    }
  
    return (
      <>
        { !(location && location.length) && 
          <div className="leaflet-coordinates-container">
            {t('coordinates_select_info')}
          </div> 
        }
        <div className="leaflet-top leaflet-right">
          <div className="leaflet-control leaflet-bar">
            <button className={styles.locate} onClick={handleLocation}>{t('pinpointing')}</button>
          </div>
          { location && location.length &&
            <div className="leaflet-control leaflet-bar">
              <button className={styles.reset} onClick={handleReset}>Reset</button>
            </div> 
          }
        </div>
      </>
    )
  }

  return (
    <div className={styles.map}>
      { locationHandler &&
        <div className={styles.coordinates}>
          <label>{`${t('coordinates')}`}</label>
          { location && location.length > 1 ?
            <div>{`${location[0]}, ${location[1]}`}</div>
          :
            <div>{t('no_coordinates')}</div> }
        </div>
      }
      { Object.keys(bounds).length > 0 &&
      <MapContainer bounds={bounds} center={center} zoom={10} style={{height: height ? height : 500, borderRadius: 15}}>
        <GestureHandlingSetter />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        { locationHandler && resetLocation && <LocationControl locationHandler={locationHandler} resetLocation={resetLocation} /> }
        { locationHandler && <LocationMarker handlePosition={locationHandler} location={location} /> }
        { markers && markers.map((marker: IMarker) => {
          return (
            <Marker position={marker.coordinates} key={marker.publicId} icon={getMarkerIcon(marker.status)}>
              <Popup closeButton={false}>
                <div className={styles.markerWrapper}>
                  <div className={classNames(styles.status, statusMap[marker.status].className)}>
                    {statusMap[marker.status].text}
                  </div>
                  <div className={styles.textWrapper}>
                    { marker.partOfFleets.map((item, i) => <div key={item+i}>{item}</div>) }
                  </div>
                  { marker.navigation && <button className={styles.navigate} onClick={(e) => handleNavigate(e, marker.publicId) } /> }
                </div>
              </Popup>
            </Marker>
          )
        }) }
      </MapContainer> }
    </div>
  )
}

export default Map;
