import { ClassAttributes, forwardRef, InputHTMLAttributes, useEffect, useRef } from "react";
import classNames from 'classnames';
import { ArrowDown, ArrowUp } from '../Sugar'
import styles from './styles.module.css'
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../util";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fi from 'date-fns/locale/fi';
import { InfoIcon } from "../../icons";
import Select, { CSSObjectWithLabel, SingleValue } from 'react-select'
registerLocale("fi", fi);

interface DropdownButtonProps {
  className: string,
  value: string,
  isOpen: boolean,
  onClick: () => void,
  shouldClose: () => void,
  children: JSX.Element
}

interface InputGroupProps {
  children: JSX.Element | JSX.Element[],
}

export type SelectOption = SingleValue<{ label: string, value: string }>

interface SelectWithLabelProps {
  onChange: (option: SelectOption) => void,
  value: string,
  options: { value: any, label: string }[],
  placeholder?: string,
  name: string,
  label: string,
  required?: boolean,
  error?: string,
  loading?: boolean,
  info?: string
}

interface CheckboxProps {
  name: string,
  checked: boolean,
  onChange: () => void
}

interface InputWithLabelProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  value?: string | number | readonly string[],
  name: string,
  label: string,
  required?: boolean,
  error?: string,
  info?: string
}

interface PasswordWithLabelProps {
  name: string,
  label: string,
  error?: string,
  info?: string
}

interface DatePickerWithLabelProps {
  name: string,
  label: string,
  value: string,
  onChange: (date: string) => void,
  required?: boolean,
  error?: string
}

interface ValueWithLabelProps {
  label: string,
  value: string | number | readonly string[]
}

export const DropdownButton = ({ value, className, children, isOpen, shouldClose, ...rest }: DropdownButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventTarget = event.target as HTMLElement
      if (buttonRef.current && contentRef.current) {
        if (!contentRef.current.contains(eventTarget) && !buttonRef.current.contains(eventTarget)) {
          isOpen && shouldClose && shouldClose();
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isOpen, shouldClose]);

  return (
    <div className={classNames(className, styles.container)}>
      <button className={classNames(styles.Button, {[styles.open]: isOpen})} {...rest} ref={buttonRef}>
        <div className={styles.main}>{ value }</div>
        <div className={styles.arrow}>{(isOpen ? <ArrowUp /> : <ArrowDown />)}</div>
      </button>
      {(isOpen && 
        <>
          <button className={styles.closeButton}>X</button>
          <div className={styles.dropdown} ref={contentRef}>
            { children }
          </div>
        </>
      )}
    </div>
  );
};

export const InputGroup = ({ children }: InputGroupProps) => (
  <div className={styles.InputGroup}>{children}</div>
);

export const TextInput = (props: JSX.IntrinsicAttributes & ClassAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <input className={styles.TextInput} type="text" {...props}></input>
  );
};

export const TextAreaInput = (props: JSX.IntrinsicAttributes & ClassAttributes<any> & InputHTMLAttributes<any>) => {
  return (
    <textarea className={styles.TextInput} rows={5} cols={60} {...props}></textarea>
  );
}; 

export const SubmitInput = (props: JSX.IntrinsicAttributes & ClassAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>) => {
  return (<input className={styles.SubmitInput} type="submit" {...props}></input>);
};

export const Checkbox = ({ name, checked, onChange, ...rest }: CheckboxProps) => {
  return (
    <input name={name} type="checkbox" className={styles.checkbox} checked={checked} onChange={onChange} {...rest} />
  );
};

export const InputWithLabel = ({ name, label, required, error, onChange, value, info, ...rest }: InputWithLabelProps) => {
  return (
    <div className={styles.inputWithLabel}>
      <label htmlFor={name}>{`${label} ${required ? '*' : ''}`}</label>
      <TextInput id={name} name={name} required={required} style={error ? { border: '1px solid red' } : undefined} autoComplete={'off'} onChange={onChange} value={value} {...rest} />
      { info ? <div className={styles.infoWrapper}><InfoIcon /> <div className={styles.infoLabel}>{info}</div></div> : null }
      { error ? <div style={{color: 'red'}}>{error}</div> : null }
    </div>
  )
}

export const PasswordWithLabel = ({ name, label, error, info, ...rest }: PasswordWithLabelProps & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div className={styles.inputWithLabel}>
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        className={styles.TextInput}
        type="password"
        style={error ? { border: '1px solid red' } : undefined}
        autoComplete={'off'}
        {...rest}
      />
      { info ? <div className={styles.infoWrapper}><InfoIcon /> <div className={styles.infoLabel}>{info}</div></div> : null }
      { error ? <div style={{color: 'red'}}>{error}</div> : null }
    </div>
  )
}

export const TextAreaWithLabel = ({ name, label, required, error, onChange, value, ...rest }: InputWithLabelProps) => {
  return (
    <div className={styles.inputWithLabel}>
      <label htmlFor={name}>{`${label} ${required ? '*' : ''}`}</label>
      <TextAreaInput id={name} name={name} required={required} style={error ? { border: '1px solid red' } : undefined} autoComplete={'off'} onChange={onChange} value={value} {...rest} />
      { error ? <div style={{color: 'red'}}>{error}</div> : null }
    </div>
  )
}

export const SelectWithLabel = ({ name, label, options, required, error, loading, placeholder, value, onChange, info }: SelectWithLabelProps) => {
  const { t } = useTranslation();

  const fontColor = 'white';
  const backgroundColor = '#6a6a69';
  const selectedColor = '#878787';

  const selectStyles = {
    control: (provided: CSSObjectWithLabel, { isFocused }: any) => ({ 
      ...provided, 
      backgroundColor: backgroundColor,
      color: fontColor,
      border: error ? '1px solid red' : '1px solid white',
      boxShadow: isFocused ? 'inset 0 0 7px black' : 'none',
      borderRadius: 10,
      heigth: 40,
      minHeight: 40,
      '&:hover': {
        borderColor: error ? 'red' : fontColor
      } 
    }),
    option: (provided: CSSObjectWithLabel, { isFocused, isSelected }: any) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isSelected ? selectedColor : backgroundColor,
      '&:hover': { 
        backgroundColor: isFocused ? selectedColor : backgroundColor
      }
    }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      marginTop: 0,
      zIndex: 10,
    }),
    menuList: (provided: CSSObjectWithLabel) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    input: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: fontColor,
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: fontColor
    }),
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({ 
      ...provided, 
      color: fontColor, 
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: fontColor,
    }),
    noOptionsMessage: (provided: CSSObjectWithLabel) => ({
      ...provided,
      backgroundColor: backgroundColor,
      color: fontColor
    })
  }

  return (
    <div className={styles.selectWithLabel}>
      <label htmlFor={name}>{`${label} ${required ? '*' : ''}`}</label>
        <Select
          value={options.find(opt => opt.value === value)}
          onChange={(option: SelectOption) => onChange(option)}
          options={options} 
          styles={selectStyles}
          placeholder={loading ? t('loading_options') : placeholder}
          isLoading={loading}
          loadingMessage={() => t('loading_options')} 
          noOptionsMessage={() => t('no_options')}
          isDisabled={loading}
        />
      { info ? <div className={styles.infoWrapper}><InfoIcon /> <div className={styles.infoLabel}>{info}</div></div> : null }
      { error ? <div style={{color: 'red'}}>{error}</div> : null }
    </div>
  )
}

export const DatePickerWithLabel = ({ name, label, value, onChange, required, error }: DatePickerWithLabelProps) => {
  const { t } = useTranslation();

  const CustomInput = forwardRef(({ value, onChange, onClick, error }: any, ref: any) => (
    <input readOnly className={styles.TextInput} type="text" value={value} onChange={onChange} onClick={onClick} style={error ? { border: '1px solid red' } : undefined} ref={ref} />
  ));

  return (
    <div className={styles.inputWithLabel}>
      <label htmlFor={name}>{`${label} ${required ? '*' : ''}`}</label>
      <DatePicker
        selected={value ? new Date(value) : ''}
        onChange={(date: Date) => onChange(date.toISOString())}
        showTimeSelect
        timeFormat={'p'}
        timeIntervals={5}
        timeCaption={t('time')}
        dateFormat={'Pp'}
        showPopperArrow={false}
        customInput={<CustomInput error={error} />}
        locale={getLanguage()}
      />
      { error ? <div style={{color: 'red'}}>{error}</div> : null }
    </div>
  )
}

export const ValueWithLabel = ({ label, value }: ValueWithLabelProps) => {
  return (
    <div className={styles.inputWithLabel}>
      <label>{label}</label>
      <div>{value}</div>
    </div>
  )
}
