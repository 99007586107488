import React from 'react';
import classNames from 'classnames';
import { useTranslation } from "react-i18next";

import styles from './styles.module.css';
import type {InputData, Point} from '../Devices/Devices';
import { formatLocaleDateTime } from '../../util';

interface StatusTimelineProps {
  inputData: InputData,
  range: [Date, Date],
}

interface Subdivision {
  time: Date | null,
  width: number,
}

interface Block {
  visualStart: Date,
  width: number,
  state: 'NO_DATA' | 'ON' | 'OFF',
  subdivisions: Subdivision[],
}

const statusMap: Record<Block['state'], string> = {
  'NO_DATA': styles.noData,
  'ON': styles.on,
  'OFF': styles.off,
}

export const StatusTimeline = ({ inputData, range }: StatusTimelineProps) => {
  const { t } = useTranslation();

  const points = inputData.points;

  const blocks: Block[] = [];
  for (let i = 0; i < points.length; i++) {
    const curr = points[i];
    const next = points[i + 1] as Point | undefined;
    // If no block yet, insert the first point starting BEFORE range start.
    if (blocks.length === 0) {
      if (curr.time > range[0]) {
        blocks.push({
          visualStart: range[0],
          width: 1e-3 * (curr.time.getTime() - range[0].getTime()),
          state: 'NO_DATA',
          subdivisions: [{
            time: null,
            width: 1e-3 * (curr.time.getTime() - range[0].getTime()),
          }],
        });
      } else if (next !== undefined && next.time > range[0]) {
        blocks.push({
          visualStart: range[0],
          width: 1e-3 * (next.time.getTime() - range[0].getTime()),
          state: points[i].value ? 'ON' : 'OFF',
          subdivisions: [{
            time: null,
            width: 1e-3 * (next.time.getTime() - range[0].getTime()),
          }],
        });
        continue;
      }
    }
    // If we already have blocks, check if we should extend the previous or start a new block.
    if (blocks.length !== 0) {
      const pointState = curr.value ? 'ON' : 'OFF';
      const currentBlock = blocks[blocks.length - 1];
      const endTime = next === undefined ? range[1] : next.time;
      currentBlock.width = 1e-3 * (curr.time.getTime() - currentBlock.visualStart.getTime());
      if (currentBlock.state === pointState) {
        currentBlock.subdivisions.push({
          time: curr.time,
          width: 1e-3 * (endTime.getTime() - curr.time.getTime()),
        });
        
      } else {
        blocks.push({
          visualStart: curr.time,
          width: 1e-3 * (endTime.getTime() - curr.time.getTime()),
          state: pointState,
          subdivisions: [{
            time: curr.time,
            width: 1e-3 * (endTime.getTime() - curr.time.getTime()),
          }],
        });
      }
    }
  }
  // If we did't get any points, insert an unkown block.
  if (blocks.length === 0) {
    blocks.push({
      visualStart: range[0],
      width: 1e-3 * (range[1].getTime() - range[0].getTime()),
      state: 'NO_DATA',
      subdivisions: [{
        time: null,
        width: 1e-3 * (range[1].getTime() - range[0].getTime()),
      }],
    });
  }

  const textMap: Record<Block['state'], string> = {
    'NO_DATA': t('no_info'),
    'ON': t('on'),
    'OFF': t('off'),
  }

  return (
    <div className={classNames(styles.StatusTimeline, styles.statusTimeLine)}>
      <div className={styles.title}>
        {inputData.name}
      </div>
      <div className={styles.timeline}>
        {blocks.map((block, i) => {
          return (
            <div
              key={i}
              className={classNames(styles.block, statusMap[block.state])}
              style={{flexGrow: block.width}}
            >
              <div className={styles.subDivisions}>
                <>{ block.subdivisions.map((subdiv, i) => (
                  <div
                    key={i}
                    style={{flexGrow: subdiv.width}}
                    className={classNames({[styles.startUnknown]: (subdiv.time === null)})}
                    data-tooltip-id={block.state !== 'NO_DATA' ? 'main-tooltip' : undefined}
                    data-tooltip-content={
                      subdiv.time !== null
                      ? formatLocaleDateTime(subdiv.time)
                      : `${t('before_time')} ${formatLocaleDateTime(block.visualStart)}`
                    }
                  />
                ))
                }</>
              </div>
              <div className={styles.text}>{textMap[block.state]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const StatusTimelineLegend = () => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.StatusTimelineLegend, styles.statusTimeLine)}>
      <div className={styles.legendOn}>{t('on')}</div>
      <div className={styles.legendOff}>{t('off')}</div>
    </div>
  );
};
