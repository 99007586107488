import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import classNames from 'classnames/bind';

import { AddButton, Centered, EditButton, Loader, statusMap } from "../Sugar";
import ChoiceWidget from "../ChoiceWidget";
import Map, { IMarker } from '../Map';

import styles from './styles.module.css';
import API from "../../api";
import { IAlert, Status } from "../../types";
import { hasEditPermissionForOrg } from "../../util";
import { useAlerts, useAuth, useTitle } from "../../hooks";
import { useTranslation } from "react-i18next";
import SectionBox from "../SectionBox";
import { t } from "i18next";
import { ALERT_LEVEL_TO_STATUS, ALERT_STATUS_TO_LEVEL, mostSevereAlertStatus, stateToStatus } from "../Alerts";

// Fleet data returned from the api keyed by the fleet publicId.
type FleetData = Record<string, {
  publicId: string,
  name: string,
  parentPublicId: string | null,
  organizationPublicId: string,
  coordinates: [number, number] | null,
}>

interface FleetProps {
  name: string,
  publicId: string,
  status: Status,
  expanded: boolean,
  parentPublicId: string | null,
  organizationPublicId: string
}

interface StationProps {
  name: string,
  publicId: string,
  status: Status,
  equipmentValues: {
    motorValues: MotorValue[],
    surfaceLevel: number | null
  }
}

type MotorValue = {
  name: string,
  value: number | null,
}

interface FleetGridProps {
  expandedIdx: number | null,
  fleet: FleetProps[],
  toggleCallback: (index: number) => void
}

interface FleetEntryProps {
  entry: FleetProps,
  onDropdownClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

interface FleetEntryDetailsProps {
  forEntry: FleetProps,
  gridPosition: {
    x: number,
    y: number
  }
}

interface InstallationRowProps {
  entry: StationProps
}

interface FleetEntryStatusProps {
  status: Status
}

interface SortItem {
  name: string,
  status: Status
}

type FleetOrder = 'name' | 'alerting';
type FleetDisplayStyle = 'grid' | 'map';

const sortItems = (items: any[], order: FleetOrder) => {
  const comparators: Record<FleetOrder, (a: SortItem, b: SortItem) => number> = {
    'name': (a: SortItem, b: SortItem) => (a.name.localeCompare(b.name)),
    'alerting': (a: SortItem, b: SortItem) => (
      -(ALERT_STATUS_TO_LEVEL[a.status] - ALERT_STATUS_TO_LEVEL[b.status])
      || a.name.localeCompare(b.name)
    ),
  }
  return items.sort(comparators[order]);
}

const formatSurfaceLevel = (value: number | null) => {
  const content = (value !== null) ? (
    <>{`${value.toFixed(2)} m`}</>
  ) : (
    <div> - </div>
  );
  return (
    <div data-tooltip-id='main-tooltip' data-tooltip-content={t('surface_level')}>
      {content}
    </div>
  );
};

const formatMotorValues = (motorValues: MotorValue[]) => {
  if (!motorValues.length) {
    return <div data-tooltip-id='main-tooltip' data-tooltip-content={t('no_monitored_motors')}>-</div>;
  }
  const rows = motorValues.map(item => {
    const content = (item.value !== null) ? (
      <div>{`${item.value}`}</div>
    ) : (
      <div> - </div>
    );
    return (
      <div
        key={item.name}
        data-tooltip-id='main-tooltip'
        data-tooltip-content={`${item.name}: ${t('num_cycles_within_24h')}`}
      >{content}</div>
    );
  });

  return rows.length > 2 ? <div> - </div> : rows;
}

const propagateAlertStatus = (fleetData: FleetData, alerts: IAlert[]): Record<string, Status> => {
  /* Given the user's fleet and the active alerts, propagate alert information
     to the parents of the alerting fleets. Returns a mapping of fleet id -> alert level
  */
  const numericAlertStatuses: Record<string, number> = {};
  for (const alert of alerts) {
    let fleetId: string | null = alert.forFleetPublicId;
    let level = ALERT_STATUS_TO_LEVEL[stateToStatus(alert.lastState)];
    while (fleetId) {
      level = Math.max(numericAlertStatuses[fleetId] || 0, level)
      numericAlertStatuses[fleetId] = level;
      fleetId = fleetData[fleetId].parentPublicId;
    }
  }
  const fleetAlertStatuses: Record<string, Status> = {};
  // Perform a second pass to convert the numeric levels back to Statuses.
  for (const fleetId in fleetData) {
    fleetAlertStatuses[fleetId] = ALERT_LEVEL_TO_STATUS[numericAlertStatuses[fleetId] || 0];
  }
  return fleetAlertStatuses;
}

const FleetGrid = ({ expandedIdx, fleet, toggleCallback }: FleetGridProps): JSX.Element => {
  const [numColumns, setNumColumns] = useState<number | null>(null);

  const colMinWidth = 300;
  let gridContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let resizeCallbackTimer: any = null;
    const handleWidthUpdate = () => {
      if (resizeCallbackTimer === null) {
        resizeCallbackTimer = setTimeout(() => {
          updateWidthNow();
        }, 50);
      }
    }
  
    const updateWidthNow = () => {
      if (gridContainer.current) {
        const width = gridContainer.current.offsetWidth;
        const numColumns = Math.max(1, Math.floor(width / colMinWidth));
        setNumColumns(numColumns);
      }
      clearTimeout(resizeCallbackTimer);
      resizeCallbackTimer = null;
    }
    window.addEventListener('resize', handleWidthUpdate);
    updateWidthNow();
    return () => {
      window.removeEventListener('resize', handleWidthUpdate);
    }
  }, [])

  let maybeEntryDetails: JSX.Element | null = null;
  if (expandedIdx !== null && numColumns !== null) {
    const forEntry = fleet[expandedIdx];
    // Calculate the XY position in the grid where the details should open. (1 row below the entry
      // and to either side.
    let entryPosx = expandedIdx % numColumns;
    if (entryPosx >= numColumns - 1) {
      entryPosx--;
    }
    const entryPosy = Math.floor(expandedIdx / numColumns);
    const detailsGridPosition = { // 1-based indexing in grid
      x: entryPosx + 1,
      y: entryPosy + 2,
    }
    maybeEntryDetails = <FleetEntryDetails key={forEntry.publicId} forEntry={forEntry} gridPosition={detailsGridPosition} />
  }
  return (
    <div ref={gridContainer} style={{
      display: 'grid',
      gridTemplateColumns: `repeat(${numColumns}, minmax(${colMinWidth}px, 1fr))`,
    }}>
      {
        fleet.map((entry, i) => (
          <FleetEntry key={entry.publicId} entry={entry} onDropdownClick={() => { toggleCallback(i) }} />
        ))
      }
      { maybeEntryDetails }
    </div>
  );
}

const FleetEntry = ({ entry, onDropdownClick }: FleetEntryProps) => {
  return (
    <div className={classNames( styles.FleetEntry, { [styles.expanded]: entry.expanded })}>
      <div className={styles.header}>
        <FleetEntryStatus status={ entry.status } />
        <div className={styles.name}>{ entry.name }</div>
        <button className={styles.dropdown} onClick={(event: React.MouseEvent<HTMLButtonElement>) => onDropdownClick(event)} />
      </div>
    </div>
  );
}

const FleetEntryDetails = ({ forEntry, gridPosition }: FleetEntryDetailsProps): JSX.Element => {
  const navigate = useNavigate();
  const { alerts, loadingAlerts } = useAlerts();
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [stations, setStations] = useState<StationProps[]>([]);
  const [fleet, setFleet] = useState<any>(null);

  useEffect(() => {
    /* TODO cancel request on unmount/update... */
    if (alerts) {
      API.getFleetChildrenById(forEntry.publicId).then(data => {
        if (data.stations) {
          const stations = data.stations.map((item: StationProps) => {
            const stationAlerts = alerts.filter(alert => alert.forFleetPublicId === item.publicId);
            const status = mostSevereAlertStatus(stationAlerts);
            return ({
              name: item.name,
              publicId: item.publicId,
              status: status,
              equipmentValues: item.equipmentValues,
            })
          })
          let sortedStations = sortItems(stations, 'name');
          if (alerts.length > 0) {
            sortedStations = sortItems(sortedStations, 'alerting');
          }
          setStations(sortedStations);
        } else {
          setFleet(data.fleet);
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [forEntry, loadingAlerts, alerts])

  const installationRows = stations.map(
    (entry: StationProps) => <InstallationRow key={entry.publicId} entry={entry} />
  );

  const hasEditPermission = hasEditPermissionForOrg(forEntry.organizationPublicId, user)

  // Update the height of the details listing based on the number of installations.
  // Also account for the possible edit buttons taking up one row of space.
  const rowSpan = Math.max(2, stations.length) + (+hasEditPermission);

  const gridColumnCSS = `${gridPosition.x} / ${gridPosition.x + 2}`
  const gridRowCSS = `${gridPosition.y} / ${gridPosition.y + rowSpan}`

  return (
    <div className={styles.FleetEntryDetails} style={{
      gridColumn: gridColumnCSS,
      gridRow: gridRowCSS,
    }}>
      { loading ? 
        <Loader /> 
      : 
        <>
          { fleet ? <FleetRow entry={fleet} /> : installationRows }
          { hasEditPermission &&
            <div className={styles.buttonsContainer}>
              <EditButton
                onClick={() => navigate(`/fleet/${forEntry.publicId}/edit`, {state: { from: 'fleet' }})}
                data-tooltip-id='main-tooltip'
                data-tooltip-content={t('edit_fleet')}
              />
              { !fleet &&
                <AddButton
                  onClick={() => navigate(`/fleet/${forEntry.publicId}/new-installation`)}
                  data-tooltip-id='main-tooltip'
                  data-tooltip-content={t('new_installation')}
                />
              }
            </div> 
          }
        </>
      }
    </div>
  );
}

const FleetEntryStatus = ({ status }: FleetEntryStatusProps) => {
  const statusClass = statusMap[status].className;
  return (
    <div className={classNames(styles.status, statusClass)}>
      <div>{ statusMap[status].text }</div>
    </div>
  );
}

const InstallationRow = ({ entry }: InstallationRowProps) => {
  const navigate = useNavigate();

  const statusClass = statusMap[entry.status].className;
  return (
    <div className={styles.InstallationRow}>
      <div className={classNames(styles.status, statusClass)}>
        {statusMap[entry.status].text}
      </div>
      <div className={styles.name}>
        <Link to={`/station/${entry.publicId}`}>{ entry.name }</Link>
      </div>
      <div className={styles.equipmentStatus}>
        <div className={styles.phaseCurrentContainer}>
          <div className={classNames(styles.icon, styles.cycles)} />
          <div className={styles.values}>{ formatMotorValues(entry.equipmentValues.motorValues) }</div>
        </div>
        <div className={styles.waterLevelContainer}>
          <div className={classNames(styles.icon, styles.level)} />
          <div>{ formatSurfaceLevel(entry.equipmentValues.surfaceLevel) }</div>
        </div>
        <button className={styles.drilldown} onClick={() => navigate(`/station/${entry.publicId}`)}></button>
      </div>
    </div>
  );
}

const FleetRow = ({ entry }: any) => {
  const navigate = useNavigate();

  return (
    <div className={classNames(styles.InstallationRow, styles.noStatus)}>
      <div className={styles.equipmentStatus}>
        <div className={styles.phaseCurrentContainer}>
          <div className={classNames(styles.icon, styles.cycles)} />
          <div className={styles.values}>{ formatMotorValues(entry.equipmentValues.motorValues) }</div>
        </div>
        <div className={styles.waterLevelContainer}>
          <div className={classNames(styles.icon, styles.level)} />
          <div>{ formatSurfaceLevel(entry.equipmentValues.surfaceLevel) }</div>
        </div>
      </div>
      <button className={styles.drilldown} onClick={() => navigate(`/station/${entry.publicId}`)}></button>
    </div>
  );
}

const Fleet = () => {
  const { t } = useTranslation();
  useTitle('Fleet');
  const routeParams = useParams();
  const navigate = useNavigate();
  const { alerts, loadingAlerts } = useAlerts();
  const { user } = useAuth();
  const [fleetData, setFleetData] = useState<FleetData | null>(null);
  const [fleet, setFleet] = useState<FleetProps[]>([]);
  const [fleetOrder, setFleetOrder] = useState<FleetOrder>('name');
  const [viewStyle, setViewStyle] = useState<FleetDisplayStyle>('grid');
  const [mapMarkers, setMapMarkers] = useState<IMarker[]>([])
  const [expandedIdx, setExpandedIdx] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fleetId = routeParams.publicId;

  useEffect(() => {
    setLoading(true);
    if (!loadingAlerts && alerts) {
      API.getFleets().then(data => {
        const fd: FleetData = {};
        for (const fleet of data.fleets) {
          fd[fleet.publicId] = fleet;
        }
        setFleetData(fd);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [loadingAlerts, alerts]);

  useEffect(() => {
    if (!fleetData || !alerts){
      return;
    }
    const fleetAlertStatuses = propagateAlertStatus(fleetData, alerts);
    if (viewStyle === 'grid') {
      // Select top level fleets from all the fleets returned to display in the grid view.
      const fleets: FleetProps[] = Object.values(fleetData).filter(
        (item) => (item.parentPublicId === null)
      ).map((item) => {
        return {
          ...item,
          status: fleetAlertStatuses[item.publicId],
          expanded: item.publicId === fleetId,
        }
      });

      const sortedFleets = sortItems(fleets, fleetOrder);
      const expandedIndex = fleets.findIndex((item: any) => item.publicId === fleetId);
      setFleet(sortedFleets);
      setExpandedIdx(expandedIndex > -1 ? expandedIndex : null);
    } else if (viewStyle === 'map') {
      // Display all fleets that have coordinates.
      const markers: IMarker[] = Object.values(fleetData).flatMap((item) => {
        if (item.coordinates === null) {
          return [];
        }
        const fleetPath: string[] = [];
        let fleet = item;
        while (true) {
          fleetPath.push(fleet.name);
          if (fleet.parentPublicId) {
            fleet = fleetData[fleet.parentPublicId];
          } else {
            break;
          }
        }
        fleetPath.reverse();
        return [{
          coordinates: {lat: item.coordinates[0], lng: item.coordinates[1]},
          partOfFleets: fleetPath,
          status: fleetAlertStatuses[item.publicId],
          publicId: item.publicId,
          navigation: true,
        }];
      });
      setMapMarkers(markers);
    }
  }, [fleetId, fleetData, fleetOrder, alerts, viewStyle, t]);

  const handleEntryToggle = (fleetEntryIdx: number) => {
    let newExpandedIdx = expandedIdx;
    if (expandedIdx === null) {
      // Just open new
      fleet[fleetEntryIdx].expanded = true;
      newExpandedIdx = fleetEntryIdx;
    } else {
      // close old.
      fleet[expandedIdx].expanded = false;
      // Are we just closing?
      if (fleetEntryIdx === expandedIdx) {
        newExpandedIdx = null;
      } else {
        fleet[fleetEntryIdx].expanded = true;
        newExpandedIdx = fleetEntryIdx;
      }
    }
    setFleet(fleet);
    setExpandedIdx(newExpandedIdx);
  }

  const handleFleetOrderChange = (event: React.FormEvent<HTMLInputElement>) => {
    const eventTarget = event.target as HTMLInputElement;
    const order = eventTarget.value as FleetOrder;
    const sortedFleets = sortItems(fleet, order);
    let newExpandedIdx = expandedIdx;
    for (const i in fleet) {
      if (fleet[i].expanded) {
        newExpandedIdx = Number(i);
        break;
      }
    }
    setFleetOrder(order);
    setFleet(sortedFleets);
    setExpandedIdx(newExpandedIdx);
  }

  const handleFleetViewStyle = (event: React.FormEvent<HTMLInputElement>) => {
    const eventTarget = event.target as HTMLInputElement;
    const view = eventTarget.value as FleetDisplayStyle;
    setViewStyle(view);
  }

  return ( 
    <>
      <div style={{display: 'flex'}}>
        <h1>Fleet</h1>
        { user && user.memberships.find(m => m.role === 'edit') &&
          <div style={{marginLeft: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <AddButton
              onClick={() => navigate('/new-fleet')}
              data-tooltip-id='main-tooltip'
              data-tooltip-content={t('new_fleet')}
              data-tooltip-place='left'
            />
          </div>
        }
      </div>

      <div style={{display: 'flex', overflowX: 'auto'}}>
        { viewStyle === 'grid' &&
          <fieldset style={{ margin: 0, padding: 10, border: 0 }}>
            <legend>{ t('order_by') }</legend>
            <ChoiceWidget
              name={'fleet-order'}
              choices={[{value: 'name', title: t('name')}, {value: 'alerting', title: t('alert_status')}]}
              selected={fleetOrder}
              onInput={handleFleetOrderChange}
            />
          </fieldset>
        }
        <fieldset style={{ margin: '0 0 0 auto', padding: 10, border: 0 }}>
          <legend>{t('view')}</legend>
          <ChoiceWidget
            name={'fleet-display-type'}
            choices={[{value: 'grid', title: t('grid')}, {value: 'map', title: t('map')}]}
            selected={viewStyle}
            onInput={handleFleetViewStyle}
          />
        </fieldset>
      </div>
      { loading && <Loader text={t('loading_fleet')} /> }
      { viewStyle === 'grid' && <FleetGrid fleet={fleet} expandedIdx={expandedIdx} toggleCallback={handleEntryToggle} /> }
      { viewStyle === 'map' && 
        <SectionBox>
          { mapMarkers.length > 0 ?
            <Map markers={mapMarkers} height={'70vh'} />
          :
            <Centered>
              { t('no_coordinates_found') }
            </Centered>
          }
        </SectionBox>
        
      }
    </>
  );
}

export default Fleet;
