import { BrowserRouter, Route, Navigate, Routes, Outlet, useLocation } from "react-router-dom";

import { Fleet, EditFleet } from "./components/Fleet";
import Station from "./components/Station";
import NavBar from "./components/NavBar";
import Login from './components/Login';
import Pump from "./components/Pump";
import { Alerts, NewMotorAlert, NewOverflowAlert } from "./components/Alerts";
import Settings from "./components/Settings";
import { Devices } from "./components/Devices";
import Wizard from "./components/Wizard";

import styles from './main.module.css';
import { PageLoader } from "./components/Sugar";
import { useAuth, useScroll } from "./hooks";
import { AuthUser } from "./types";
import { useEffect, useRef } from "react";
import { Tooltip } from "react-tooltip";

interface RouteProps {
  user?: AuthUser | null
}

const ProtectedRoute = ({ user }: RouteProps) => {
  const mainRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const { scrollTop } = useScroll();

  useEffect(() => {
    if (scrollTop && mainRef.current) {
      mainRef.current.scrollTo(0, 0);
    }
  }, [scrollTop, mainRef])

  if (!user) {
    return <Navigate to={`/login?redirect=${location.pathname+location.search}`} replace />;
  }

  return (
    <div className={styles.baseUI}>
      <NavBar />
      <div className={styles.mainPanelWrapper}>
        <div className={styles.mainPanel} ref={mainRef}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const Redirect = ({ user }: RouteProps) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <Navigate to="/fleet" replace />;
  }

const LoginRedirect = ({ user }: RouteProps) => {
  const location = useLocation();

  if (!user) {
    return <Login />;
  }
  
  const redirect = location.search.replace('?redirect=', '');

  return <Navigate to={redirect ? redirect : "/fleet"} replace />;
}

const App = () => {
  const { user, loadingAuthStatus } = useAuth();

  return (
    <div className={styles.app}>
      { loadingAuthStatus ?
        <PageLoader /> 
      :
      <>
        <BrowserRouter>
          <Routes>
            <Route element={<ProtectedRoute user={user} />}>
              <Route path="/fleet" element={<Fleet />} />
              <Route path="/fleet/:publicId" element={<Fleet />} />
              <Route path="/station/:publicId" element={<Station />} />
              <Route path="/pump/:publicId" element={<Pump />} />
              <Route path="/devices/" element={<Devices />} />
              <Route path="/devices/:serial" element={<Devices />} />
              <Route path="/alerts" element={<Alerts />} />
              <Route path="/settings" element={<Settings />} />

              <Route path="/new-fleet" element={<Wizard />} />
              <Route path="/fleet/:publicId/new-installation" element={<Wizard />} />
              <Route path="/station/:publicId/new-pump" element={<Wizard />} />
              <Route path="/station/:publicId/new-surface-level" element={<Wizard />} />
              <Route path="/fleet/:publicId/edit" element={<EditFleet />} />
              <Route path="/station/:stationPublicId/new-motor-alert/:equipmentPublicId" element={<NewMotorAlert />} />
              <Route path="/station/:stationPublicId/new-overflow-alert" element={<NewOverflowAlert />} />
            </Route>
            <Route path="/login" element={<LoginRedirect user={user} />} />
            <Route path="*" element={<Redirect user={user} />} />
          </Routes>
        </BrowserRouter>
        <Tooltip id="main-tooltip" />
      </>
      }
    </div>
  );
}

export default App;
