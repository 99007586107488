import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { getLanguage } from "../../util";
import { FlexSectionBoxLayout, FlexSectionBox } from '../FlexSectionBoxLayout'
import { PasswordWithLabel, SelectWithLabel, SubmitInput } from "../Inputs";
import type { SelectOption } from "../Inputs"
import API from "../../api";
import { useNotification, useTitle } from "../../hooks";
import { SpinnerOverlay } from "../Sugar";


interface ChangePasswordResponse {
  success: boolean,
  error?: string,
}

const ChangePassword = (): JSX.Element => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordAgain, setNewPasswordAgain] = useState<string>('');
  // Error flag if the server indicated that the old password was not correct.
  const [currentPasswordError, setCurrentPasswordError] = useState<boolean>(false);
  // Error flag if the new password is too weak, as returned by the server.
  const [newPasswordTooWeakError, setNewPasswordTooWeakError] = useState<boolean>(false);
  // Error flag if the given new passwords do not match.
  const [newPasswordAgainError, setNewPasswordAgainError] = useState<boolean>(false);
  const [changeInProgress, setChangeInProgress] = useState<boolean>(false);

  const handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(event.target.value);
    setCurrentPasswordError(false);
  }

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    setNewPasswordAgainError(false);
    setNewPasswordTooWeakError(false);
  }

  const handleNewPasswordAgainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPasswordAgain(event.target.value);
    setNewPasswordAgainError(false);
  }

  const checkPasswordsMatch = (): boolean => {
    const match = newPassword === newPasswordAgain;
    setNewPasswordAgainError(!match);
    return match;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (checkPasswordsMatch()) {
      setChangeInProgress(true);
      API.changePassword({currentPassword: currentPassword, newPassword: newPassword}).then((data: ChangePasswordResponse) => {
        if (data.success) {
          setNotification(t("password_changed"), 'OK');
          setCurrentPasswordError(false);
          setNewPasswordTooWeakError(false);
          setNewPasswordAgainError(false);
          setCurrentPassword('');
          setNewPassword('');
          setNewPasswordAgain('');
        } else if (data.error) {
          if (data.error === 'ERR_INVALID_PASSWORD') {
            setCurrentPasswordError(true);
          } else if (data.error === 'ERR_PASSWORD_TOO_WEAK') {
            setNewPasswordTooWeakError(true);
          } else {
            setNotification(t("general_error"), 'DANGER');
          }
        }
      }).finally(() => {
        setChangeInProgress(false);
      });
    }
  };

  return (
    <>
      <h2>{t("change_password_title")}</h2>
      <SpinnerOverlay cover={changeInProgress}>
        <form onSubmit={handleSubmit}>
          <PasswordWithLabel
            name='user-current-password'
            value={currentPassword}
            onChange={handleCurrentPasswordChange}
            label={t("current_password")}
            error={currentPasswordError ? t("incorrect_current_password") : undefined}
            disabled={changeInProgress}
            autoComplete="current-password"
          />
          <PasswordWithLabel
            name='user-new-password'
            value={newPassword}
            onChange={handleNewPasswordChange}
            label={t("new_password")}
            error={newPasswordTooWeakError ? t("password_too_weak_error_hint") : undefined}
            disabled={changeInProgress}
            autoComplete="new-password"
          />
          <PasswordWithLabel
            name='user-new-password-again'
            value={newPasswordAgain}
            onChange={handleNewPasswordAgainChange}
            label={t("new_password_again")}
            error={newPasswordAgainError ? t("passwords_do_not_match") : undefined}
            disabled={changeInProgress}
            autoComplete="new-password"
            onBlur={checkPasswordsMatch}
          />
          <SubmitInput value={t("change_password_button")} disabled={changeInProgress} />
        </form>
      </SpinnerOverlay>
    </>
  )
};

const Settings = () => {
  const { t } = useTranslation();
  useTitle(t("settings"));
  const [language, setLanguage] = useState<string>(getLanguage);

  const handleLanguageChange = (option: SelectOption) => {
    if (option) {
      setLanguage(option.value);
      i18n.changeLanguage(option.value);
    }
  }

  const languageOptions = [
    {value: 'fi', label: t("finnish")},
    {value: 'en', label: t("english")},
  ];

  return (
    <>
      <FlexSectionBoxLayout>
        <FlexSectionBox title={t("display_settings")}>
          <SelectWithLabel
            name='interface-language'
            label={t("interface_language")}
            options={languageOptions}
            onChange={handleLanguageChange}
            value={language}
          />
        </FlexSectionBox>
        <FlexSectionBox title={t("user_settings")}>
          <ChangePassword />
        </FlexSectionBox>
      </FlexSectionBoxLayout>
    </>
  )
}

export default Settings;
