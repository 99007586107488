import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';


interface FlexSectionBoxLayoutProps {
  children: JSX.Element | JSX.Element[],
}

const FlexSectionBoxLayout = ({ children }: FlexSectionBoxLayoutProps): JSX.Element => (
  <div className={ classNames(styles.sectionBoxContainer) }>
    {children}
  </div>
);

interface FlexSectionBoxProps {
  title: string,
  children: JSX.Element | JSX.Element[],
}

const FlexSectionBox = ({ title, children }: FlexSectionBoxProps): JSX.Element => (
  <div className={ classNames(styles.sectionBox) }>
    <h1>{title}</h1>
    <div>
      {children}
    </div>
  </div>
);

export { FlexSectionBoxLayout, FlexSectionBox };
