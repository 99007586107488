import { AlertState, IAlert, Status } from '../../types';
import {NewOverflowAlert, NewMotorAlert} from './NewAlert';
import Alerts from './Alerts';
import { t } from 'i18next';

const STATE_TO_STATUS_MAP: Record<AlertState, Status> = {
  'OK': 'OK',
  'PENDING': 'WARNING',
  'ALERTING': 'DANGER',
  'NO_DATA_OK': 'WARNING',
  'NO_DATA_PENDING': 'WARNING',
  'NO_DATA_ALERTING': 'DANGER',
}

const ALERT_STATUS_TO_LEVEL: Record<Status, number> = {
  'OK': 0,
  'WARNING': 1,
  'DANGER': 2,
}

const ALERT_LEVEL_TO_STATUS: Record<number, Status> = Object.fromEntries(
  Object.entries(ALERT_STATUS_TO_LEVEL).map(([k, v]) => [v, k as Status])
);

const stateToStatus = (state: AlertState): Status => (STATE_TO_STATUS_MAP[state]);

const mostSevereAlertStatus = (alerts: IAlert[]): Status => {
  let worst = 0;
  for (const a of alerts) {
    const status = STATE_TO_STATUS_MAP[a.lastState];
    if (status === 'DANGER') {
      return 'DANGER';
    }
    worst = Math.max(worst, ALERT_STATUS_TO_LEVEL[status]);
  }
  return ALERT_LEVEL_TO_STATUS[worst];
};

const describeRuleState = (state: AlertState): string => {
  return {
    'OK': '',
    'PENDING': t('rule_is_pending'),
    'ALERTING': t('rule_is_alerting'),
    'NO_DATA_OK': t('rule_is_no_data_ok'),
    'NO_DATA_PENDING': t('rule_is_no_data_pending'),
    'NO_DATA_ALERTING': t('rule_is_no_data_alerting'),
  }[state]
}

export {
  Alerts,
  NewOverflowAlert,
  NewMotorAlert,
  describeRuleState,
  mostSevereAlertStatus,
  stateToStatus,
  ALERT_STATUS_TO_LEVEL,
  ALERT_LEVEL_TO_STATUS,
}
