import { useEffect } from "react";

export const useTitle = (title: string) => {
  /* Set the document.title in a component. */
  useEffect(() => {
    // const prevTitle = document.title;
    document.title = title;
    // Nevermind: restoring the title requires using the hook very diligently. Not possible at the moment.
    // return () => {
    //   document.title = prevTitle;
    // };
  });
};
