import React from 'react';
import Plotly from "react-plotly.js"

import type {InputData} from './Devices';

interface DeviceInputChartProps {
  inputDataArray: InputData[],
  range: [Date, Date],
  yTitle: string,
  colors: string[],
}

const DeviceInputsChart = ({ inputDataArray, range, yTitle, colors }: DeviceInputChartProps) => {
  const plotlyDataArray: Plotly.Data[] = inputDataArray.map((inputData, index: number) => {
    return {
      type: 'scatter',
      mode: 'lines+markers',
      x: inputData.points.map(p => p.time.getTime()),
      y: inputData.points.map(p => p.value),
      yhoverformat: '.2f',
      line: {
        shape: 'hv',
        color: colors[index],
      },
      name: inputData.name,
      connectgaps: false,
    };
  });

  return (
    <Plotly
      data={plotlyDataArray}
      layout={{
        legend: {
          y: -0.2
        },
        xaxis: {
          showgrid: false,
          color: 'black',
          range: range.map(d => d.getTime()),
          type: 'date',
        },
        yaxis: {
          title: yTitle,
          rangemode: 'tozero',
          color: 'black',
          gridcolor: '#7C7C7B',
        },
        autosize: true,
        showlegend: false,
        margin: {
          l: 50,
          r: 15,
          t: 10,
          b: 35,
          pad: 5
        },
        hovermode: 'x unified',
        hoverlabel: {
          font: {
            color: 'black',
          },
          bgcolor: 'white'
        },
        font: {
          color: 'white',
        },
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        dragmode: 'zoom',
      }}
      config={{
        displaylogo: false,
        responsive: true,
        displayModeBar: false,
      }}
      style={{width: '100%', height: 200}}
    />
  )
}

export default DeviceInputsChart;
