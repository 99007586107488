import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import API from "../api";
import { AuthUser, LoginData, LoginResponse, Membership } from "../types";

interface IAuthContext {
  login: (loginData: LoginData) => Promise<any>,
  logout: () => Promise<any>,
  setError: () => void,
  error: string,
  user: AuthUser | null,
  loadingLogin: boolean,
  loadingAuthStatus: boolean
}

interface IAuthStatusResponse {
  email: string,
  name: string,
  memberships: Membership[],
  status?: number
}

const AuthContext = createContext<Partial<IAuthContext>>({});

export const AuthProvider = ({ children }: any): JSX.Element => {
  const { t } = useTranslation();
  const [user, setUser] = useState<AuthUser | null>(null);
  const [error, setError] = useState<string>('');
  const [loadingLogin, setLoadingLogin] = useState<boolean>(false);
  const [loadingAuthStatus, setLoadingAuthStatus] = useState<boolean>(true);

  useEffect(() => {
    authStatus();
  }, [])

  const authStatus = () => {
    API.authStatus().then((res: IAuthStatusResponse) => {
      setUser(res);
    }).catch(() => {
      setUser(null);
    }).finally(() => {
      setLoadingAuthStatus(false);
    })
  }

  const login = (loginData: LoginData): Promise<any> => {
    setLoadingLogin(true);
    return API.login(loginData).then((res: LoginResponse) => {
      const { email, name, memberships, success, error } = res;
      if (success) {
        setUser({ email, name, memberships })
        setError('');
      } else if (error) {
        setError(t(error));
      }
    }).catch(() => {
      setError(t('login_error'));
    }).finally(() => {
      setLoadingLogin(false);
    })
  }

  const logout = (): Promise<any> => {
    return API.logout().then(() => {
      setUser(null);
    })
  };

  return (
    <AuthContext.Provider value={{ login, logout, error, user, loadingLogin, loadingAuthStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);