import React from 'react';

const Info = () => {
  return (
  <svg width="33" height="25" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 0C7.38705 0 0 7.38705 0 16.5C0 25.6129 7.38705 33 16.5 33C25.6129 33 33 25.6129 33 16.5C33 7.38705 25.6129 0 16.5 0ZM14.85 8.25H18.15V11.55H14.85V8.25ZM14.85 14.85H18.15V24.75H14.85V14.85Z" fill="#0093E5"/>
  </svg>
  )
}

export default Info;
