import styles from './styles.module.css';
import { ArrowDown, ArrowRight } from "../Sugar";

interface TitleProps {
  name: string,
  open: boolean,
  toggle: () => void
}

interface CollapsibleProps {
  open: boolean,
  children: JSX.Element
}

export const Title = ({ name, open, toggle }: TitleProps) => {
  return (
    <div className={styles.titleContainer} onClick={toggle}>
      <h1 className={styles.name}>{name}</h1>
      <div className={styles.arrow}>{ open ? <ArrowDown /> : <ArrowRight /> }</div>
    </div>
  )
}

const Collapsible = ({ open, children }: CollapsibleProps) => {
  return (
    <div>
      { open ? children : null }
    </div>
  )
}

export default Collapsible;