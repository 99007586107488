import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../hooks';
import { LoginData } from '../types';

interface ErrorData {
  response?: {
    data: {
      error?: string
    }
  },
  message?: string
}

const instance: AxiosInstance = axios.create({
  baseURL: '/api'
});

const request = <DataType>({ ...options }: AxiosRequestConfig): Promise<DataType> => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };
  const onError = (error: AxiosError) => {
    return Promise.reject(error);
  }
  const onFinally = () => {
  }
  return instance(options).then(onSuccess).catch(onError).finally(onFinally);
}

export const AxiosInterceptor = ({ children }: any) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  
  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => {
      return response;
    };

  const errInterceptor = (error: ErrorData) => {
    let message = t('general_error');
    if (error?.response?.data?.error) {
      message = t(error.response.data.error);
    }
    if (error?.message !== 'canceled') {
      setNotification(message, 'DANGER');
    }
    return Promise.reject(error);
  };

  const interceptor = instance.interceptors.response.use(
    resInterceptor,
    errInterceptor
  );

    return () => instance.interceptors.response.eject(interceptor);
  }, [setNotification, t]);

  return children;
};

export interface ChangePasswordData {
  currentPassword: string,
  newPassword: string,
}


/* Start to systemically type the api responses. Hopefully some day complete.*/
export interface ApiFleet {
  publicId: string,
  organizationPublicId: string,
  parentPublicId: string | null,
  name: string,
  address: string,
  locality: string | null,
  postalCode: string | null,
  coordinates: [number, number] | null,
}

// "Part of fleet" list does not contain full fleet information, just id and name.
type PartOfFleetEntry = Pick<ApiFleet, 'publicId' | 'name'>

interface ApiEquipment {
  publicId: string,
  name: string,
  motor?: {
    modelName: string | null,
    nominalVoltageVolts: number | null,
    nominalCurrentAmperes: number | null,
    nominalSpeedRpm: number | null,
    nominalFrequencyHertz: number | null,
    nominalPowerFactor: number | null,
    numPolePairs: number | null,
    nominalPower: number | null,
  },
  pump?: {
    modelName: string | null,
    nominalPower: number | null,
    nominalHeadM: number | null,
    nominalFlowRate: number | null,
  }
}

interface ApiEquipmentSeries {
  id: string,
  quantity: string,
  hasAlertRules: boolean,
}

interface ApiDeviceInstallation {
  serial: string,
  typeCode: string,
  installTime: string,
  uninstallTime: string | null,
}

export interface ApiStation {
  fleet: ApiFleet & {children: ApiFleet[]},
  partOfFleets: PartOfFleetEntry[],
  equipment: (
    ApiEquipment & {series: ApiEquipmentSeries[]}
  )[],
  deviceInstallations: ApiDeviceInstallation[],
}

export interface ApiEquipmentDetails {
  partOfFleets: PartOfFleetEntry[],
  equipment: (
    ApiEquipment & {series: ApiEquipmentSeries[]}
  ),
  deviceInstallation: ApiDeviceInstallation,
}

const API = {
  login: (loginData: LoginData) => request<any>({ url: '/auth', method: 'post', data: loginData }),
  logout: () => request<any>({ url: '/deauth', method: 'post' }),
  changePassword: (changePasswordData: ChangePasswordData) => request<any>({ url: '/change-password', method: 'post', data: changePasswordData, validateStatus: (status) => !(status in [400, 403]) }),
  authStatus: () => request<any>({ url: '/auth-status' }),
  getFleets: () => request<any>({ url: '/fleets' }),
  getFleetById: (id: string) => request<any>({ url: `/fleet/${id}` }),
  getFleetChildrenById: (id: string) => request<any>({ url: `/fleet/${id}/children` }),
  getFleetCoordinates: () => request<any>({ url: '/fleet-coordinates' }),
  getStationById: (id: string, abortSignal?: AbortSignal) => request<ApiStation>({ url: `/station/${id}`, signal: abortSignal }),
  getStationEquipmentById: (id: string) => request<any>({ url: `/station/${id}/equipment` }),
  getSeries: (id: string, params: string, abortSignal?: AbortSignal) => request<any>({ url: `/dataproxy/series/${id}?${params}`, signal: abortSignal }),
  getEquipmentDetailsById: (id: string) => request<ApiEquipmentDetails>({ url: `/equipment/${id}` }),
  getEquipmentSeries: (id: string, params: string) => request<any>({ url: `/dataproxy/equipment-series/${id}?${params}` }),
  getPumpOperatingPoint: (id: string, params: string) => request<any>({ url: `/dataproxy/pump-operating-point/${id}?${params}` }),
  getActiveAlerts: () => request<any>({ url: '/active-alerts' }),
  getAlerts: (params: string) => request<any>({ url: `/alerts?${params}` }),
  getIntermediateSeries: (id: string, params: string, abortSignal?: AbortSignal) => request<any>({ url: `/dataproxy/intermediate-series/${id}?${params}`, signal: abortSignal }),
  getDevices: () => request<any>({ url: '/devices' }),
  getAvailableDevices: () => request<any>({ url: '/available-devices' }),
  getDevice: (serial: string) => request<any>({ url: `/device/${serial}` }),
  getDeviceData: (serial: string, abortSignal?: AbortSignal) => request<any>({ url: `/deviceData/${serial}`, signal: abortSignal }),

  addNewFleet: (data: any) => request<any>({ url: '/fleet', method: 'post', data: data }),
  addNewStation: (data: any) => request<any>({ url: '/station', method: 'post', data: data }),
  addNewEquipment: (data: any) => request<any>({ url: '/equipment', method: 'post', data: data }),
  addNewAlert: (data: any) => request<any>({ url: '/alert', method: 'post', data: data }),
  editFleet: (data: any, id: String) => request<any>({ url: `/fleet/${id}`, method: 'patch', data: data })
}

export default API;