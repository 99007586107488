import styles from './styles.module.css';

export type MeterProps = {
  progress: number,
  value?: number,
  title: string,
  color?: 'green' | 'yellow' | 'red',
}

const Meter = ({ progress, value, color, title }: MeterProps): JSX.Element => {
  const strokeLength = ((2 * Math.PI * 55 / 360) * 290);
  const progressLength = (strokeLength / 100) * (100 - progress);
  const width = 152;
  const strokeDasharray = '280, 350';
  const fill = 'none';
  const colorValue = () => {
    if (color) {
      return color === 'green' ? '#009640' : (color === 'yellow' ? '#EFB600' : '#E52000');
    } else {
      return progress < 50 ? '#93C152' : (progress < 75 ? '#EFB600' : '#E52000');
    }
  }
  
  let val: number | string = 0;
  if (value && typeof value === 'number') {
    if (value < 1) {
      val = value.toFixed(2).toString().replace('0.', '.');
    } else if (value >= 100) {
      val = value.toFixed(0);
    } else {
      val = value.toFixed(1);
    }
  }
  
  return (
    <div style={{ width: width }} className={styles.meter}>
      <svg
        width={width}
        height={width}
        viewBox={`0 0 ${width} ${width}`}
        style={{transform: 'rotate(125deg)'}}
      >
        <circle
          cx={width/2}
          cy={width/2}
          r={55}
          fill={fill}
          stroke={'#a5a5a4'}
          strokeWidth={22}
          strokeDasharray={strokeDasharray}
        />
        <circle
          cx={width/2}
          cy={width/2}
          r={55}
          fill={fill}
          stroke={colorValue()}
          strokeWidth={22}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={progressLength}
        />
      </svg>
      <div style={{color: colorValue()}} className={styles.value}>{ val ? val : progress }</div>
      <div className={styles.title}>{ title }</div>
    </div>
  )

}

export default Meter;