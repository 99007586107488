import { Status } from "../types";

interface AlertProps {
  status: Status,
  count: number | null,
}

const Alert = ({ status, count }: AlertProps) => (
  <svg
    width={181.417}
    height="48mm"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M115.948 176.995h-15.306c.678 3.852 3.843 6.769 7.653 6.769 3.81 0 6.976-2.917 7.653-6.77m-8.22-41.23h-.021c-1.11 0-2.009.959-2.009 2.142v2.568c-2.506.629-4.815 2.292-6.28 4.998l-9.622 17.767c-3.014 5.566.754 12.525 6.783 12.525h22.855c6.029 0 9.797-6.959 6.783-12.525l-9.621-17.767c-1.578-2.913-4.133-4.62-6.858-5.124v-2.442c0-1.183-.9-2.142-2.01-2.142m6.838 42.344c-1 2.697-3.465 4.543-6.271 4.543s-5.271-1.846-6.271-4.543h12.542m-6.838-41.231c.533 0 .966.461.966 1.029v3.377l.865.161c2.617.485 4.795 2.114 6.133 4.585l9.62 17.766a7.576 7.576 0 0 1 0 7.238c-1.227 2.266-3.424 3.618-5.878 3.618H96.58c-2.454 0-4.652-1.352-5.879-3.618a7.576 7.576 0 0 1 0-7.238l9.621-17.766c1.237-2.286 3.232-3.873 5.616-4.472l.805-.202v-3.449c0-.568.433-1.03.965-1.03h.022"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.718519,
      }}
      transform="translate(-84 -135.764)"
    />
    { count &&
      <>
        <path
          d="M128.81 161.327h-6.069c-4.377 0-7.925-3.783-7.925-8.45v-6.471c0-4.667 3.548-8.45 7.925-8.45h6.07c4.376 0 7.924 3.783 7.924 8.45v6.471c0 4.667-3.548 8.45-7.925 8.45"
          style={{
            fill: `var(${status === 'WARNING' ? '--vt-warning' : '--vt-danger'})`,
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.718519,
          }}
          transform="translate(-88.735 -138)"
        /> 
        <text
          style={{
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "15.9683px",
            fontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
            fill: "#fff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.718519,
          }}
          transform="matrix(.96846 0 0 1.03257 -88.735 -138)"
        >
          <tspan
            x={count >= 10 ? 122 : 126}
            y={150}
            style={{
              strokeWidth: 0.718519,
            }}
          >
            {count}
          </tspan>
        </text>
      </> 
    }
  </svg>
)

export default Alert;
