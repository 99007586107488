import ReactDOM from 'react-dom';
import './index.css';
import 'react-tooltip/dist/react-tooltip.css'
import App from './App';
import { AuthProvider, AlertProvider, NotificationProvider, ScrollProvider } from './hooks';
import './i18n';
import { AxiosInterceptor } from './api';

ReactDOM.render(
  <NotificationProvider>
    <ScrollProvider>
      <AxiosInterceptor>
        <AuthProvider>
          <AlertProvider>
            <App />
          </AlertProvider>
        </AuthProvider>
      </AxiosInterceptor>
    </ScrollProvider>
  </NotificationProvider>,
  document.getElementById('root')
);
