import { ClassAttributes, InputHTMLAttributes, useState } from "react";
import { Spinner, PoweredBy, ErrorMsg } from "../Sugar";

import styles from './styles.module.css';
import { LoginData } from "../../types";
import { useAuth, useTitle } from "../../hooks";
import { useTranslation } from "react-i18next";

interface PasswordFieldProps {
  name: string,
  placeholder: string,
  value: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface ForwardButtonProps {
  loading?: boolean,
  text: string
}

const TextField = (props: JSX.IntrinsicAttributes & ClassAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  return (
    <input type="text" {...props} className={styles.vtInput} />
  );
}

const PasswordField = ({ name, placeholder, value, onChange }: PasswordFieldProps): JSX.Element => {
  return (
    <input 
      type="password" 
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={styles.vtInput}
    />
  )
}

const ForwardButton = ({ loading, text }: ForwardButtonProps): JSX.Element => {
  return (
    <button className={styles.vtInput} disabled={loading}>
      <div>
        <div style={{flexGrow: 1}}>{text}</div>
        <div className={styles.rightSlot}>
          { loading ? <Spinner /> : <ForwardsArrow /> }
        </div>
      </div>
    </button>
  )
}

const ForwardsArrow = (): JSX.Element => {
  return (<div className={styles.forwardsArrow} />);
}

const LoginForm = (): JSX.Element => {
  const { t } = useTranslation();
  useTitle(t('login_title'));
  const { login, loadingLogin, error } = useAuth();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const loginData: LoginData = {
      'username': username,
      'password': password
    }
    login && login(loginData);
  }

  return (
    <form onSubmit={handleSubmit}>
      {error && <ErrorMsg message={error} />}
      <p>
        <TextField 
          name="username" 
          placeholder={ t('username') }
          autoFocus={true} 
          value={username}
          onChange={evt => setUsername(evt.target.value)}
        />
      </p>
      <p>
        <PasswordField 
          name="password" 
          placeholder={ t('password') }
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
        />
      </p>
      <p>
        <ForwardButton text={t('login')} loading={loadingLogin} />
      </p>
    </form>
  );
}

const Login = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.Login}>
      <div style={{marginTop: '5vh'}}>
        <h1>{ t('login_title') }</h1>
      </div>
      <LoginForm />
      <div style={{marginBottom: '5vh'}}>
        <PoweredBy />
      </div>
    </div>
  );
}

export default Login;