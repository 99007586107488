import React from 'react'
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import { IAlert } from '../../types';
import { formatLocaleDateTime } from '../../util';
import styles from './styles.module.css'
import { statusMap, ArrowRight } from "../Sugar";
import { describeRuleState, stateToStatus } from '../Alerts';


interface RecentAlertProps {
  alertHistory: IAlert[],
  showAlerting: boolean,
  onJumpToAlert?: (alert: IAlert) => void,
}

const RecentAlerts = ({ alertHistory, showAlerting, onJumpToAlert }: RecentAlertProps) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.RecentAlerts, {[styles.hasActiveAlerts]: showAlerting})}>
      <div className={styles.content}>
        { alertHistory.map(alert => {
          const status = stateToStatus(alert.lastState);
          return ( 
            <div key={alert.publicId} className={styles.alertRow}>
              <div className={classNames(styles.status, statusMap[status].className)}>
                {statusMap[status].text}
              </div>
              <div className={styles.alertContent}>
                <div>{ alert.forEquipmentName }: { alert.forSeriesName } { describeRuleState(alert.lastState) } </div>
                <div>
                  { formatLocaleDateTime(alert.startTime) }
                  { alert.endTime && (
                    ' – ' + formatLocaleDateTime(alert.endTime)
                  )}
                </div>
              </div>
              { onJumpToAlert && <div className={styles.rowButton}>
                <button
                  onClick={() => onJumpToAlert(alert)}
                  data-tooltip-id='main-tooltip'
                  data-tooltip-content={t('jump_to_alert_tooltip')}
                  data-tooltip-place='top'
                >
                  <ArrowRight />
                </button>
              </div>}
            </div>
          );
        }) 
        }
      </div>
    </div>
  );
};

export default RecentAlerts;
