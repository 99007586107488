import { createContext, useContext, useEffect, useState } from "react";

interface IScrollContext {
  scrollToTop: () => void,
  scrollTop: boolean
}

const ScrollContext = createContext<IScrollContext>({} as any);

export const ScrollProvider = ({ children }: any): JSX.Element => {
  const [scrollTop, setScrollTop] = useState<boolean>(false);

  useEffect(() => {
    if (scrollTop) {
      setScrollTop(false);
    }
  }, [scrollTop])

  const scrollToTop = (): void => {
    setScrollTop(true);
  }

  return (
    <ScrollContext.Provider value={{ scrollToTop, scrollTop }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);