import classNames from 'classnames';
import { Status } from '../../types';
import styles from './styles.module.css';

interface SectionBoxProps {
  layoutArea?: any,
  title?: string,
  status?: Status,
  children: JSX.Element | JSX.Element[],
  marginTop?: boolean,
  noOverflow?: boolean
}

const SectionBox = ({ layoutArea, title, children, status, marginTop, noOverflow }: SectionBoxProps): JSX.Element => {
  const extraStyle = layoutArea && {gridArea: layoutArea};
  const statusStyle = status === 'DANGER' ? styles.danger : '';
  const overflowStyle = noOverflow ? {overflow: 'unset'} : undefined;

  return (
    <div className={ classNames(styles.sectionBox, { [styles.marginTop]: marginTop }) } style={extraStyle}>
      { title && <h1 className={statusStyle}>{ title }</h1> }
      <div style={overflowStyle}>{ children }</div>
    </div>
  );
}

export default SectionBox;