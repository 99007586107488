import classNames from 'classnames/bind';
import React, { useEffect, useState, useRef } from 'react';

import styles from './styles.module.css'

interface Choice {
  value: string,
  title: string
}

interface ChoiceWidgetProps {
  name: string,
  choices: Choice[],
  selected: string,
  onInput: (event: React.FormEvent<HTMLInputElement>) => void
}

const ChoiceWidget = ({ name, choices, selected, onInput }: ChoiceWidgetProps) => {
  const originalSelection = useRef(selected);
  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    if (originalSelection.current !== selected) {
      setChanged(true);
    }
  }, [selected]);

  return (
    <div className={ styles.ChoiceWidget }>
      { choices.map(item => (
        <label key={item.value} className={classNames({
          [styles.selected]: (selected === item.value),
          [styles.changed]: changed})
        }>
          <input type="radio" name={name} value={item.value} onInput={onInput} />
          {item.title}
        </label>))
      }
    </div>
  );
};

export default ChoiceWidget;
