import { useState } from "react";

import Calendar from 'react-calendar';
import classNames from 'classnames/bind';

import { formatDateTime, getLanguage } from "../../util";
import {DropdownButton, InputGroup, TextInput, SubmitInput} from "../Inputs";

import './ReactCalendar.css';

import styles from './styles.module.css'
import { useTranslation } from "react-i18next";

interface TimerangePickerProps {
  onChange: (range: string[]) => void,
  value: string[],
  name: string
}

export const relativeRanges: string[] = [
  "now-30min",
  "now-1h",
  "now-3h",
  "now-6h",
  "now-12h",
  "now-24h",
  "now-2d",
  "now-7d",
  "now-30d",
  "now-6m",
  "now-1y",
  "now-2y",
  "now-5y",
];

const rangeNames: Record<string, [string, number]> = {
  "now-30min": ['last_minutes', 30],
  "now-1h": ['last_hour', 1],
  "now-3h": ['last_hour', 3],
  "now-6h": ['last_hour', 6],
  "now-12h": ['last_hour', 12],
  "now-24h": ['last_hour', 24],
  "now-2d": ['last_days', 2],
  "now-7d": ['last_days', 7],
  "now-30d": ['last_days', 30],
  "now-6m": ['last_months', 6],
  "now-1y": ['last_year', 1],
  "now-2y": ['last_year', 2],
  "now-5y": ['last_year', 5],
};

export const rangeSubtractionInMs: Record<string, number> = {
  "now-30min": 18e5,
  "now-1h": 36e5,
  "now-3h": 108e5,
  "now-6h": 216e5,
  "now-12h": 432e5,
  "now-24h": 864e5,
  "now-2d": 1728e5,
  "now-7d": 6048e5,
  "now-30d": 26298e5,
  "now-6m": 157788e5,
  "now-1y": 315576e5,
  "now-2y": 631152e5,
  "now-5y": 157788e6,
};

const isValidDatetimeEntry = (val: string) => {
  if (
    /^now *(- *\d+ ?(s|min|h|d|m|y))?$/.test(val) ||
    /^\d\d\d\d-\d\d-\d\d( \d\d:\d\d:\d\d$)/.test(val)
  ) {
    return true;
  }
  return false;
};

const TimerangePicker = ({ onChange, value, name }: TimerangePickerProps): JSX.Element => {
  const { t } = useTranslation();
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const [enteredStart, setEnteredStart] = useState<string>('');
  const [enteredEnd, setEnteredEnd] = useState<string>('');

  const handleRelativeRangeChoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredStart(event.target.value);
    setEnteredEnd('now');

    setDropDownOpen(false);
    onChange && onChange([event.target.value, 'now']);
  };

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredStart(event.target.value);
  };

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredEnd(event.target.value);
  };

  const handleEnteredValues = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValidDatetimeEntry(enteredStart)) {
      return
    }
    if (!isValidDatetimeEntry(enteredEnd)) {
      return
    }
    onChange && onChange([enteredStart, enteredEnd]);
    setDropDownOpen(false);
  };

  const handleButtonClick = () => {
    if (!dropDownOpen) {
      // Opening the dropdown
      setEnteredStart(value[0]);  
      setEnteredEnd(value[1]);
    }
    setDropDownOpen(!dropDownOpen);
  };

  const handleShouldClose = () => {
    setDropDownOpen(false);
  };

  const handleCalendarOnChange = (range: Date[]) => {
    setEnteredStart(formatDateTime(range[0]));
    if (range.length === 2) {
      setEnteredEnd(formatDateTime(range[1]));
      onChange && onChange([formatDateTime(range[0]), formatDateTime(range[1])]);
      setDropDownOpen(false);
    }
  }

  const getRangeTranslation = (range: string) => {
    return t(rangeNames[range][0], { count: rangeNames[range][1]});
  }

  let pickedRelativeRange: string | null = null;
  if (value[1] === 'now' && value[0] in rangeNames) {
    pickedRelativeRange = value[0]
  }

  const displayedValue = pickedRelativeRange ? getRangeTranslation(pickedRelativeRange) : `${value[0]} - ${value[1]}`;

  return (
    <DropdownButton 
      className={styles.TimerangePicker} 
      value={displayedValue}
      isOpen={dropDownOpen} 
      onClick={handleButtonClick} 
      shouldClose={handleShouldClose}
    >
      <div className={styles.content}>
        <div className={styles.absranges}>
          <form onSubmit={handleEnteredValues}>
            <label htmlFor={`${name}-from`}>{t('from')}</label>
            <InputGroup>
              <TextInput id={`${name}-from`} value={enteredStart} onChange={handleFromChange} autoFocus={true} ></TextInput>
            </InputGroup>
            <label htmlFor={`${name}-to`}>{t('to')}</label>
            <InputGroup>
              <TextInput id={`${name}-to`} value={enteredEnd} onChange={handleToChange}></TextInput>
            </InputGroup>
            <div className={styles.submitrow}>
              <SubmitInput value={t('apply_time_range')} />
            </div>
          </form>
          <div className={styles.calendar}>
            <Calendar minDetail={'year'} selectRange={true} onChange={handleCalendarOnChange} allowPartialRange={true} locale={getLanguage()}/>
          </div>
        </div>
        <div className={styles.relranges}>
          <ul>
            { relativeRanges.map((value) => (
              <li key={value}>
                <label className={classNames({[styles.selected]: pickedRelativeRange === value })}>
                  <input type="radio" name={`${name}-relrange`} value={value} onInput={handleRelativeRangeChoice}/>
                  { getRangeTranslation(value) }
                </label>
              </li> 
            ))}
          </ul>
        </div>
      </div>
    </DropdownButton>
  );
};

export default TimerangePicker;
