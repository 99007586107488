import classNames from "classnames";
import { Link } from "react-router-dom";
import { Status } from "../../types";

import { ArrowLeft, statusMap } from '../Sugar';

import styles from './styles.module.css';

export interface BreadcrumbProps {
  leftArrowTo: string,
  links: BreadcrumbLink[],
  status?: Status,
}

export interface BreadcrumbLink {
  to?: string,
  title: string,
}

const Breadcrumbs = ({ leftArrowTo, links, status }: BreadcrumbProps): JSX.Element => {
  return (
    <div className={styles.BreadcrumbsWrapper}>
      <div className={styles.Breadcrumbs}>
        <Link className={classNames(styles.arrow, styles.item)} to={leftArrowTo}><ArrowLeft /></Link>
        { links.map((item) => (
          item.to
          ? <Link className={styles.item} key={item.to} to={item.to}>{item.title}</Link>
          : <div className={styles.item} key={item.title}>{item.title}</div>
        )) }
        { status && (
          <div className={classNames(styles.status, statusMap[status].className)}>
            { statusMap[status].text }
          </div>
        ) }
      </div>
    </div>
  )
}

export default Breadcrumbs;
