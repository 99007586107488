import icon_fleet from '../../icons/fleet_icon.svg';
import icon_search from '../../icons/search_icon.svg';
import icon_settings from '../../icons/settings_icon.svg';
import { AlertIcon } from '../../icons';
import icon_fieldDevice from '../../icons/field_device.svg';

import { useLocation, useNavigate } from "react-router-dom";
import classNames from 'classnames';
import styles from './styles.module.css';
import { useAuth, useAlerts } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { mostSevereAlertStatus } from '../Alerts';
import { useMemo } from 'react';
import { Status } from '../../types';

interface NavButtonProps {
  active?: boolean,
  text?: string,
  onClick?: () => void,
  icon: string | JSX.Element
}

const NavButton = ({ active, icon, text, onClick }: NavButtonProps): JSX.Element => {
  return (
    <button className={classNames(styles.navButton, {[styles.active]: active})}
      onClick={onClick}
    >
      { typeof(icon) === 'string' ?
        <div className={styles.icon} style={{ backgroundImage: `url(${icon})` }} />
      :
        <div className={styles.iconComponent}>{icon}</div>
      }
      <div className={classNames(styles.navButtonText, {[styles.active]: active})}>{text}</div>
    </button>
  );
}

const SettingsButton = (): JSX.Element => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout && logout();
  }

  return (
    <div className={styles.hoverButtonPopoverWrapper}>
      <button className={styles.navButton}
        style={{ backgroundImage: `url(${icon_settings})` }}
      />
      <div className={styles.hoverButtonPopoverContent}>
        <div className={styles.settingsIcon} style={{ backgroundImage: `url(${icon_settings})` }} />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className={styles.linkButton} onClick={() => navigate('/settings')}>
            { t('settings') }
          </div>
          { user && 
            <div className={styles.settingsUserInfo}>
              <div>{ user.name }</div>
              <div>{ user.email }</div>
            </div> 
          }
          <div className={styles.linkButton} onClick={handleLogout}>
            { t('logout') }
          </div>
        </div>
      </div>
    </div>
  );
}

const NavBar = () => {
  const { t } = useTranslation();
  const { alerts, alertsCount } = useAlerts();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isActive = () => {
    if (pathname === '/fleet') {
      return true;
    } else if (pathname === '/alerts') {
      return true;
    } else if (pathname.startsWith('/devices')) {
      return true;
    }
  }

  // Badge color determined by highest alert status of the user's alerts.
  const alertStatus: Status = useMemo(() => {
    return mostSevereAlertStatus(alerts || []);
  }, [alerts]);

  return (
    <div className={classNames(styles.navBar, {[styles.active]: isActive()})}>
      <NavButton text='Fleet' icon={icon_fleet} active={pathname === '/fleet'} onClick={() => navigate('/fleet')} />
      <NavButton text={t('devices')} icon={icon_fieldDevice} active={pathname.startsWith('/devices')} onClick={() => navigate('/devices')} />
      <NavButton icon={icon_search} />
      <NavButton text={t('alerts')} icon={<AlertIcon status={alertStatus} count={alertsCount} />} active={pathname === '/alerts'} onClick={() => navigate('/alerts')} />
      <SettingsButton />
    </div>
  )
};

export default NavBar;