import classNames from "classnames";
import React, {useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import API from "../../api";
import { IAlert } from "../../types";
import { useTitle } from "../../hooks";
import { durationBetweenDates, formatLocaleDateTime } from "../../util";
import SectionBox from "../SectionBox"
import { ArrowRight, PageLoader, statusMap } from "../Sugar";
import { Config, Pagination } from "../Table";
import styles from './styles.module.css';
import { describeRuleState, stateToStatus } from ".";


type AlertListOrder = 'severity' | 'startTime';

const formatParamsString = (order: AlertListOrder, page: number, fleet: string | null): string => {
  // Map the user visible ordering choices to api params.
  const orderParams = order === 'severity' ? '-severity,-startTime' : '-startTime';
  const params = new URLSearchParams({
    page: (page - 1).toString(),
    orderBy: orderParams,
  });
  if (fleet !== null) {
    params.append('fleet', fleet);
  }
  return params.toString();
};

const Alerts = () => {
  const { t } = useTranslation();
  useTitle(t('alerts'));
  const [searchParams, setSearchParams] = useSearchParams();
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const [alertsLoading, setAlertsLoading] = useState<boolean>(true);
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalAlerts, setTotalAlerts] = useState<number>(0);
  const [fleetName, setFleetName] = useState<string | null>(null);

  const currentPage: number = useMemo(() => {
    const page = searchParams.get('page');
    if (!page || isNaN(Number(page)) || Number(page) < 1) {
      return 1;
    }
    return Number(page);
  }, [searchParams])

  const currentOrder: AlertListOrder = useMemo(() => {
    const order = searchParams.get('orderBy');
    if (!order || !(order === 'severity' || order === 'startTime')) {
      return 'severity';
    }
    return order;
  }, [searchParams])

  const currentFleetFilter: string | null = useMemo(() => {
    return searchParams.get('fleet');
  }, [searchParams])

  useEffect(() => {
    // Load alert list.
    setAlertsLoading(true);
    const queryParams = formatParamsString(currentOrder, currentPage, currentFleetFilter);
    let isMounted = true;
    API.getAlerts(queryParams).then(data => {
      if (isMounted) {
        setAlerts(data.alerts);
        setPageCount(data.pageCount);
        setTotalAlerts(data.totalAlerts);
      }
    }).finally(() => {
      if (isMounted) {
        setAlertsLoading(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [currentPage, currentOrder, currentFleetFilter]);

  useEffect(() => {
    // Load fleet info if looking at alerts of a specific fleet.
    let isMounted = true;
    if (currentFleetFilter) {
      const abortRequest = new AbortController();
      API.getStationById(currentFleetFilter, abortRequest.signal).then(data => {
        if (isMounted) {
          setFleetName(data.fleet.name);
        }
      });
      return () => {
        isMounted = false;
        abortRequest.abort();
      };
    } else {
      setFleetName(null);
    }
  }, [currentFleetFilter]);

  const handlePageChange = (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  }

  const handleOrderChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === 'severity' || value === 'startTime') {
      searchParams.set('orderBy', value);
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }
  return (
    <div className={styles.Alerts}>
      <h1>{ t('alerts') }</h1>

      { (currentFleetFilter && fleetName === null) ? (
        <PageLoader text={t('loading_alerts')} />
      ) : (
        <>
          { fleetName && (
            <p>{t('alerts_for')} <Link to={`/station/${currentFleetFilter}`}>{fleetName}</Link>.</p>
          )}

          <Config
            tableConfig={totalAlerts ? {
              page: currentPage,
              totalEntries: totalAlerts,
              rowsLength: alerts.length
            } : undefined}
            orderConfig={{
              name: 'alert-order',
              choices: [{value: 'startTime', title: t('start_time')}, {value: 'severity', title: t('severity')}],
              order: currentOrder,
              onChange: handleOrderChange
            }}
          />

          { alertsLoading ?
            <PageLoader text={t('loading_alerts')} />
          : (
            <>
              <SectionBox>
                <div className={styles.alertList}>
                  <div className={styles.header}>
                    <div className={styles.details} >{t('alert')}</div>
                    <div className={styles.startTime} >{t('start_time')}</div>
                    <div className={styles.endTime} >{t('end_time')}</div>
                    <div className={styles.duration} >{t('duration')}</div>
                  </div>

                  { alerts.map(alert => {
                      const status = stateToStatus(alert.lastState);
                      const fleetId = alert.forFleetPublicId;
                      return (
                        <div key={alert.publicId} className={styles.alert}>
                          <div className={classNames(styles.status, statusMap[status].className)}>{statusMap[status].text}</div>
                          <div className={styles.details}>
                            <div>
                            <Link to={`/station/${fleetId}`}>
                              { alert.forFleetName } / { alert.forEquipmentName }: { alert.forSeriesName }
                            </Link> {describeRuleState(alert.lastState)}
                            </div>
                          </div>
                          <div className={styles.startTime}>{formatLocaleDateTime(alert.startTime)}</div>
                          <div className={styles.endTime}>{alert.endTime ? formatLocaleDateTime(alert.endTime) : t('ongoing')}</div>
                          <div className={styles.duration}>{alert.endTime ? durationBetweenDates(new Date(alert.endTime), new Date(alert.startTime)) : '-'}</div>
                          <div className={styles.nav}><Link to={`/station/${fleetId}`} ><ArrowRight /></Link></div>
                        </div>
                      )
                    })}
                </div>
              </SectionBox>
              { pageCount > 0 && (
                <Pagination page={currentPage} pageCount={pageCount} handlePageChange={handlePageChange} />
              ) }
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Alerts;
