import { createContext, useContext, useEffect, useState } from "react";
import API from "../api";
import { IAlert } from "../types";
import { useAuth } from "./useAuth";

interface IAlertContext {
  alerts: IAlert[] | null,
  alertsCount: number | null,
  loadingAlerts: boolean,
}

interface IAlertResponse {
  alerts: IAlert[],
  count: number
}

const AlertContext = createContext<IAlertContext>({
  alerts: null,
  alertsCount: null,
  loadingAlerts: true,
});

export const AlertProvider = ({ children }: any): JSX.Element => {
  const { user } = useAuth();
  const [alerts, setAlerts] = useState<IAlert[] | null>(null);
  const [alertsCount, setAlertsCount] = useState<number | null>(null);
  const [loadingAlerts, setLoadingAlerts] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      getActiveAlerts();
    }
  }, [user])

  const getActiveAlerts = () => {
    API.getActiveAlerts().then((res: IAlertResponse) => {
      setAlerts(res.alerts);
      setAlertsCount(res.count);
    }).catch(() => {
      setAlerts(null);
      setAlertsCount(null);
    }).finally(() => {
      setLoadingAlerts(false);
    })
  }

  return (
    <AlertContext.Provider value={{ alerts, alertsCount, loadingAlerts }}>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlerts = () => useContext(AlertContext);