import React from 'react';
import { useTranslation } from "react-i18next";
import { FleetFormData, Coordinates, FormError } from "../../types";
import SectionBox from "../SectionBox";
import { InputWithLabel, SelectWithLabel } from "../Inputs";
import Map from "../Map";
import styles from './styles.module.css';

interface FleetFormProps {
  formData: FleetFormData,
  handleFormChange: (inputName: string, value: string) => void,
  handleLocationChange: (coords: Coordinates) => void,
  handleLocationReset: () => void,
  errors: FormError[],
  title: string | JSX.Element,
  organizations?: {
    value: any,
    label: string
  }[]
}

const FleetForm = ({ handleFormChange, handleLocationChange, handleLocationReset, formData, errors, title, organizations }: FleetFormProps) => {
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleFormChange(e.target.name, e.target.value);
  }

  const getValue = (name: keyof FleetFormData) => {
    return ((formData && formData[name]) || '') as string;
  }

  const getError = (name: keyof FleetFormData) => {
    let error = errors.find(err => err[name]);
    return error ? error[name] : '';
  }

  const getCoordinates = () => {
    return (formData && formData['coordinates']) || null;
  }

  return (
    <div className={styles.formContainer}>
      <SectionBox noOverflow>
        <form>
          <h2>{title}</h2>
          { organizations && <SelectWithLabel name={'organizationPublicId'} label={t('organization')} options={organizations} onChange={(option) => option && handleFormChange('organizationPublicId', option.value)} value={getValue('organizationPublicId')} error={getError('organizationPublicId')} placeholder={`-- ${t('select_organization')} --`} required /> }
          <InputWithLabel name={'name'} label={t('name')} onChange={handleChange} value={getValue('name')} error={getError('name')} required />
          <InputWithLabel name={'address'} label={t('street_address')} onChange={handleChange} value={getValue('address')} error={getError('address')} />
          <InputWithLabel name={'postalCode'} label={t('postal_code')} onChange={handleChange} value={getValue('postalCode')} error={getError('postalCode')} />
          <InputWithLabel name={'locality'} label={t('locality')} onChange={handleChange} value={getValue('locality')} error={getError('locality')} />
          <Map locationHandler={handleLocationChange} resetLocation={handleLocationReset} location={getCoordinates()} />
        </form>
      </SectionBox>
    </div>
  )
}

export default FleetForm;