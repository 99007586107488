import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import API from '../../api';
import { FleetFormData, Coordinates, FormError } from "../../types";
import { FleetForm } from '../Forms';
import { fleetSchema } from '../Forms/Validations';
import { PageLoader, Button } from '../Sugar';
import { hasEditPermissionForOrg } from '../../util';
import { useAuth, useNotification, useScroll } from '../../hooks';
import * as yup from 'yup';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import Title from '../Title';

const EditFleet = () => {
  const { publicId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const { scrollToTop } = useScroll();
  const { user } = useAuth();
  const location = useLocation();
  const [formData, setFormData] = useState<FleetFormData>({});
  const [errors, setErrors] = useState<FormError[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [title, setTitle] = useState<string | null>(null);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);

  const routerState = location.state as any;

  useEffect(() => {
    if (publicId) {
      API.getFleetById(publicId).then(data => {
        if (!hasEditPermissionForOrg(data.fleet.organizationPublicId, user)) {
          navigate('/fleet');
        }
        const fleetNames = data.partOfFleets.map((item: any) => `${item.name} - `).join('');
        const title = fleetNames + t('edit');
        setFormData(data.fleet)
        setTitle(title);
      }).catch(() => {
        navigate('/fleet');
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [publicId, t, navigate, user])

  const handleSave = () => {
    if (publicId) {
      fleetSchema.validate(formData, {abortEarly: false}).then(() => {
        setLoadingSave(true);
        API.editFleet(formData, publicId).then(() => {
          setNotification(t('data_saved'));
          handleNavigate()
        }).catch(() => {
          setNotification(t('error_submit'), 'DANGER');
          setLoadingSave(false);
        })
      }).catch((err: yup.ValidationError) => {
        const newErrors: FormError[] = [];
        err.inner.forEach(item => {
          newErrors.push({
            [item.path as string]: item.errors[0]
          });
        })
        setNotification(t('required_information_missing'), 'WARNING');
        scrollToTop();
        setErrors(newErrors);
      })
    }
  }

  const handleNavigate = () => {
    if (routerState?.from === 'fleet') {
      navigate('/fleet', {state: { fleetId: publicId }});
    } else if (routerState?.from === 'station') {
      navigate(`/station/${publicId}`);
    } else {
      navigate('/fleet');
    }
  }

  const handleFormChange = (inputName: string, value: string) => {
    setFormData({
      ...formData,
      [inputName]: value
    })
    let newErrors = [...errors];
    newErrors = newErrors.filter(err => !Object.keys(err).includes(inputName));
    setErrors(newErrors);
  }

  const handleLocationChange = (coords: Coordinates | null) => {
    setFormData({
      ...formData,
      coordinates: coords ? [coords.lat, coords.lng] : null
    });
  }

  return (
    <div className={styles.editFleet}>
      { title !== null && <Title title={title} /> }
      { loading && <PageLoader /> }
      <h1>{ title }</h1>
      <FleetForm
        formData={formData}
        errors={errors}
        handleFormChange={(inputName, value) => handleFormChange(inputName, value)}
        handleLocationChange={(coords) => handleLocationChange(coords)}
        handleLocationReset={() => handleLocationChange(null)}
        title={t('fleet_information')}

      />
      <div className={styles.buttonsContainer}>
        <Button onClick={handleNavigate} text={t('cancel')} disabled={loadingSave} type={'DANGER'} />
        <Button onClick={handleSave} text={t('save')} loading={loadingSave} />
      </div>
    </div>
  )
}

export default EditFleet;