import classNames from "classnames";
import { Status } from "../../types";
import { ArrowRight } from "../Sugar";
import styles from './styles.module.css';

interface ButtonProps {
  text: string,
  bgColor?: 'light' | 'danger' | 'warning' | undefined,
  textColor?: 'green',
  status?: Status,
  onClick: () => void,
  marginTop?: boolean,
  minHeight?: boolean
}

const Button = ({ text, bgColor, textColor, status, onClick, marginTop, minHeight, ...rest }: ButtonProps): JSX.Element => {
  const statusStyle = status === 'DANGER' ? styles.danger : '';
  const textColorStyle = textColor === 'green' ? styles.green : '';
  const marginStyle = marginTop ? styles.marginTop : '';
  const minHeightStyle = minHeight ? styles.minHeight : '';
  let bgColorStyle = '';
  if (bgColor === 'light') {
    bgColorStyle = styles.bgLight
  } else if (bgColor === 'danger') {
    bgColorStyle = styles.bgDanger
  } else if (bgColor === 'warning') {
    bgColorStyle = styles.bgWarning
  }

  return (
    <button className={classNames(styles.vt, statusStyle, bgColorStyle, marginStyle, minHeightStyle)} onClick={onClick} {...rest}>
      <div className={styles.content}>
        <div className={classNames(styles.text, textColorStyle)}>{text}</div>
        <div className={styles.arrow}>
          <ArrowRight />
        </div>
      </div>
    </button>
  )
}

export default Button;