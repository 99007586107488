import * as yup from 'yup';
import i18n from '../../../i18n';

const emptyStringToNull = (value: string, originalValue: string) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

const optionalPositiveNumber = yup.number().positive(i18n.t('positive_number')).transform(emptyStringToNull).nullable().typeError(i18n.t('positive_number'));
const requiredPositiviveNumber = yup.number().positive(i18n.t('positive_number')).typeError(i18n.t('positive_number'));
const requiredString = (maxLength: number) => yup.string().max(maxLength, i18n.t('max_length_characters', { count: maxLength })).required(i18n.t('required_field'));
const optionalString = (maxLength: number) => yup.string().max(maxLength, i18n.t('max_length_characters', { count: maxLength })).nullable();

export const fleetSchema: yup.ObjectSchema<Record<string, any>> = yup.object().shape({
  name: requiredString(50),
  address: optionalString(200),
  locality: optionalString(100),
  postalCode: optionalString(10),
  lat: optionalPositiveNumber,
  lon: optionalPositiveNumber
});

export const fleetOrganizationSchema: yup.ObjectSchema<Record<string, any>> = fleetSchema.shape({
  organizationPublicId: yup.string().required(i18n.t('required_field'))
})

export const equipmentSchema: yup.ObjectSchema<Record<string, any>> = yup.object().shape({
  serial: requiredString(50),
  from: yup.date().typeError(i18n.t('required_field')).required(i18n.t('required_field')),
  surface_level: yup.boolean(),
  CL1: requiredPositiviveNumber,
  machines: yup.array().of(
    yup.object().shape({
      name: requiredString(50),
      equipmentType: yup.string().oneOf(['motor+pump', 'motor']),
      CT: requiredPositiviveNumber,
      pump: yup.object().shape({
        model_name: optionalString(50),
        nominal_power_watts: optionalPositiveNumber,
        nominal_head_m: optionalPositiveNumber,
        nominal_flow_rate_l_per_s: optionalPositiveNumber,
        curve_speed_rpm: optionalPositiveNumber,
      }),
      motor: yup.object().shape({
        model_name: optionalString(50),
        nominal_power_watts: optionalPositiveNumber,
        nominal_voltage_volts: optionalPositiveNumber,
        nominal_current_amperes: optionalPositiveNumber,
        nominal_speed_rpm: optionalPositiveNumber,
        nominal_power_factor: yup.number().min(0, i18n.t('number_between', { from: 0, to: 1 })).max(1, i18n.t('number_between', { from: 0, to: 1 })).concat(optionalPositiveNumber),
    })
  }))
});

export const digitalInputAlertSchema: yup.ObjectSchema<Record<string, any>> = yup.object().shape({
  normalState: yup.string().oneOf(['on', 'off']).required(i18n.t('required_field')),
});
