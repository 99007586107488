import { createContext, useContext, useEffect, useState } from "react";
import { Status } from "../types";
import Notification from '../components/Notification';

interface INotificationContext {
  setNotification: (message: string, type?: Status, length?: number) => void,
}

interface INotification {
  message: string,
  type: Status,
  length: number
}

const NotificationContext = createContext<INotificationContext>({} as any);

export const NotificationProvider = ({ children }: any): JSX.Element => {
  const [noti, setNoti] = useState<INotification | null>(null);

  useEffect(() => {
    if (noti) {
      const timer = setTimeout(() => {
        setNoti(null);
      }, noti.length);
      return () => clearTimeout(timer);
    }
  }, [noti])

  const setNotification = (message: string, type: Status = 'OK', length: number = 5000): void => {
    setNoti({ message: message, type: type, length: length});
  }

  return (
    <NotificationContext.Provider value={{ setNotification }}>
      <>
        {children}
        { noti &&
          <Notification message={noti.message} type={noti.type} />
        }
      </>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);