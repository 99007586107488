import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Status } from '../../types';
import { statusMap } from '../Sugar';
import { Transition } from 'react-transition-group';
import styles from './styles.module.css'

interface NotificationProps {
  message: string,
  type: Status
}

const defaultStyle = {
  transition: 'opacity 1000ms',
  opacity: 0,
}

const transitionStyles: Record<string, React.CSSProperties> = {
  entering: { opacity: 0, transform: 'scale(0.8)' },
  entered:  { opacity: 1, transform: 'translateX(0)', transition: 'transform 200ms' },
  exiting:  { opacity: 1 },
  exited:  { opacity: 0 },
};

const Notification = ({ message, type }: NotificationProps) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(true)
  }, [])
  
  return (
    <Transition nodeRef={nodeRef} in={visible} timeout={200}>
      {(state: string) => {
        return (
          <div className={classNames(styles.notification, statusMap[type].className)} ref={nodeRef} 
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
          >
            { message }
          </div>
        )
      }}
    </Transition>
  );
};

export default Notification;