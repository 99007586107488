import React from 'react';
import { useTranslation } from "react-i18next";
import { FormError } from "../../types";
import SectionBox from "../SectionBox";
import { SelectWithLabel } from "../Inputs";
import styles from './styles.module.css';

export interface AlertFormData {
  normalState?: 'on' | 'off',  // Normal (not alerting) state of the signal.
}

interface AlertFormProps {
  formData: AlertFormData,
  handleFormChange: (inputName: string, value: string) => void,
  errors: FormError[],
  title: string | JSX.Element,
  type: 'motor' | 'overflow',
  inputInfo: string
}

const AlertForm = ({ handleFormChange, formData, errors, title, type, inputInfo }: AlertFormProps) => {
  const { t } = useTranslation();

  const getValue = (name: keyof AlertFormData) => {
    return ((formData && formData[name]) || '') as string;
  }

  const getError = (name: keyof AlertFormData) => {
    let error = errors.find(err => err[name]);
    return error ? error[name] : '';
  }

  const options = [
    {label: t('voltage_off'), value: 'off'},
    {label: t('voltage_on'), value: 'on'},
  ]

  return (
    <div className={styles.formContainer}>
      <SectionBox noOverflow>
        <form>
          <h2>{title}</h2>
          <SelectWithLabel
            name={'di'}
            label={type === 'motor' ? t('motor_protection_device_normal_mode') : t('overflow_alert_normal_mode')}
            info={inputInfo}
            options={options}
            onChange={(option) => option && handleFormChange('normalState', option.value)}
            value={getValue('normalState')}
            error={getError('normalState')}
            placeholder={`-- ${t('select')} --`}
            required
          />
        </form>
      </SectionBox>
    </div>
  )
}

export default AlertForm;
