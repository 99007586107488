import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatLocaleDateTime, hasEditPermissionForOrg } from "../../util";
import { Checkbox, DatePickerWithLabel, InputWithLabel, SelectWithLabel, ValueWithLabel } from "../Inputs";
import SectionBox from "../SectionBox";
import Title from "../Title";
import { AddButton, ArrowButton, CopyButton, PageLoader, RemoveButton, Button } from "../Sugar";
import * as yup from 'yup';
import styles from './styles.module.css';
import API from "../../api";
import Collapsible from "../Collapsible";
import { useTranslation, TFunction } from "react-i18next";
import { useAuth, useNotification, useScroll } from "../../hooks";
import { Coordinates, DeviceData, FleetFormData, FormError, EquipmentType } from "../../types";
import { FleetForm } from '../Forms';
import { fleetSchema, fleetOrganizationSchema, equipmentSchema } from '../Forms/Validations';
import { DeviceInputsTable } from "../Devices";
import { InfoIcon } from "../../icons";
import { formatInputValue } from "../Devices/DeviceInputsTable";
import ChoiceWidget from "../ChoiceWidget";

type FormName = 'fleet' | 'installation' | 'equipment';

type Config = Record<string, {
  startStep: number,
  onSubmit: (formData: FormData) => Promise<any>,
  type: 'fleet' | 'station' | 'pump' | 'surfaceLevel'
}>

interface MachineError {
  [key: string]: {
    pump: FormError,
    motor: FormError,
    name?: string,
    CT?: string,
    equipmentType?: string,
  }
}

interface Errors {
  fleet: FormError[],
  installation: FormError[],
  equipment: FormError[],
  machines: MachineError[]
}

interface IPumpForm {
  model_name?: string,
  nominal_power_watts?: number,
  nominal_head_m?: number,
  nominal_flow_rate_l_per_s?: number,
  curve_speed_rpm?: number
}

interface IMotorForm {
  model_name?: string,
  nominal_power_watts?: number,
  nominal_speed_rpm?: number,
  nominal_voltage_volts?: number,
  nominal_current_amperes?: number,
  nominal_power_factor?: number
}

interface MachineFormData {
  name: string,
  CT: number,
  equipmentType: EquipmentType,
  pump: IPumpForm,
  motor: IMotorForm
}

type MachineKey = 'name' | 'CT' | 'equipmentType';

interface EquipmentFormData {
  serial?: string,
  from?: string,
  surface_level: boolean,
  CL1: number,
  machines: MachineFormData[]
}

interface FormData {
  fleet: FleetFormData,
  installation: FleetFormData,
  equipment: EquipmentFormData,
  fleetPublicId?: string
}

interface InstallationData {
  hasSurfaceLevel: boolean,
  pumpCount: number
}

interface FormProps {
  handleFormChange: (formName: FormName, inputName: string, value: string) => void,
  errors: FormError[],
  withInstallation?: boolean,
}

interface EquipmentFormProps extends FormProps {
  formData: EquipmentFormData,
}

interface MotorPumpFormProps {
  handleFormChange: (index: number, inputName: string, value: string, type: 'pump' | 'motor' | 'common') => void,
  formData: MachineFormData[],
  handlePumpAdd: () => void,
  handlePumpCopy: () => void,
  handlePumpRemove: (index: number) => void,
  errors: MachineError[],
  installationData: InstallationData | null,
  deviceData?: DeviceData | null
}

interface SurfaceLevelFormProps {
  handleFormChange: (formName: FormName, inputName: string, value: string) => void,
  handleSurfaceLevelToggle?: (value: boolean) => void,
  formData: EquipmentFormData,
  errors: FormError[],
  deviceData?: DeviceData | null
}

const localizedEquipmentName = (t: TFunction, equipmentType: EquipmentType, num: number): string => {
  const displayType = {
    'motor': 'motor',
    'motor+pump': 'pump',
  }[equipmentType];
  return `${t(displayType)} ${num}`;
};

const EquipmentForm = ({ handleFormChange, formData, errors, withInstallation }: EquipmentFormProps) => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState<{label: string, value: string}[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!withInstallation) {
      API.getAvailableDevices().then((data => {
        setDevices(data.devices.map((device: string) => {
          return (
            { label: device, value: device }
          )
        }))
      })).finally(() => {
        setLoading(false);
      })
    }
  }, [withInstallation])

  const getValue = (name: keyof EquipmentFormData) => {
    return (formData && formData[name]) || '';
  }

  const getDateValue = () => {
    return new Date((formData && formData['from']) || '');
  }

  const handleDateChange = (date: string) => {
    handleFormChange('equipment', 'from', date);
  }

  const getError = (name: string) => {
    let error = errors.find(err => err[name]);
    return error ? error[name] : '';
  }

  return (
    <div className={styles.equipmentFormContainer}>
      <SectionBox noOverflow>
        <form>
          <h2>{t('device_information')}</h2>
          { withInstallation ?
            <>
              <ValueWithLabel label={t('device_serial')} value={getValue('serial') as string} />
              { getValue('from') && <ValueWithLabel label={t('installation_date')} value={formatLocaleDateTime(getDateValue())} /> }
            </>
          : 
            <>
              <SelectWithLabel name={'serial'} label={t('device_serial')} options={devices} onChange={(option) => option && handleFormChange('equipment', 'serial', option.value)} value={getValue('serial') as string} error={getError('serial')} placeholder={`-- ${t('select_device')} --`} required loading={loading} />
              <DatePickerWithLabel name={'from'} label={t('installation_date')} onChange={handleDateChange} value={getValue('from') as string} error={getError('from')} required />
            </>
          }
        </form>
      </SectionBox>
    </div>
  )
}

const MotorPumpForm = ({ handleFormChange, formData, handlePumpAdd, handlePumpCopy, handlePumpRemove, errors, installationData, deviceData }: MotorPumpFormProps) => {
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, equipmentIndex: number, type: 'pump' | 'motor' | 'common') => {
    handleFormChange(equipmentIndex, e.target.name, e.target.value, type);
  }

  const getValue = (name: MachineKey, index: number) => {
    return (formData && formData[index] && formData[index][name]) || '';
  }

  const getPumpValue = (name: keyof IPumpForm, index: number) => {
    return (formData && formData[index] && formData[index]['pump'][name]) || '';
  }

  const getMotorValue = (name: keyof IMotorForm, index: number) => {
    return (formData && formData[index] && formData[index]['motor'][name]) || '';
  }

  const getError = (name: MachineKey, pump: string) => {
    const error = errors.find(item => item[pump]);
    return (error && error[pump] && error[pump] && error[pump][name]) ? error[pump][name] : '';
  }

  const getPumpError = (name: keyof IPumpForm, pump: string) => {
    const error = errors.find(item => item[pump]);
    return (error && error[pump] && error[pump]['pump'] && error[pump]['pump'][name]) ? error[pump]['pump'][name] : '';
  }

  const getMotorError = (name: keyof IMotorForm, pump: string) => {
    const error = errors.find(item => item[pump]);
    return (error && error[pump] && error[pump]['motor'] && error[pump]['motor'][name]) ? error[pump]['motor'][name] : '';
  }

  const pumps = formData.map((pump, i: number) => {
    const { name } = pump;
    let count = i + 1;
    if (installationData && installationData.pumpCount) {
      count = installationData.pumpCount + 1
    }
    const input = deviceData && deviceData.inputs.find(item => item.input === `CT${count}`);
    const type = (deviceData && deviceData.type) || '';
    return (
      <SectionBox marginTop key={i}>
        <div className={styles.machineFormContainer}>
          { i > 0 && 
            <div className={styles.removeButtonWrapper}>
              <RemoveButton onClick={() => handlePumpRemove(i)} />
            </div> 
          }
          <h2>{getValue('name', i)}</h2>

          <ChoiceWidget
            name={'equipmentType-' + i}
            choices={[{value: 'motor+pump', title: t('pump')}, {value: 'motor', title: t('motor_only')}]}
            selected={getValue('equipmentType', i) as EquipmentType}
            onInput={(e) => {
              const val = (e.target as HTMLInputElement).value as EquipmentType;
              handleFormChange(i, 'equipmentType', val, 'common')
              handleFormChange(i, 'name', localizedEquipmentName(t, val, count), 'common')
            }}
          />

          <div className={styles.machineInformationContainer}>
            { getValue('equipmentType', i) === 'motor+pump' && (
              <div className={styles.pump}>
                <h3>{t('pump_technical_information')}</h3>
                <InputWithLabel name={'model_name'} label={t('brand_and_model')} onChange={e => handleChange(e, i, 'pump')} value={getPumpValue('model_name', i)} error={getPumpError('model_name', name)} />
                <InputWithLabel name={'nominal_power_watts'} label={`${t('nominal_power')} (kW)`} onChange={e => handleChange(e, i, 'pump')} value={getPumpValue('nominal_power_watts', i)} error={getPumpError('nominal_power_watts', name)} />
                <InputWithLabel name={'nominal_flow_rate_l_per_s'} label={`${t('nominal_flow_rate')} (m³/h)`} onChange={e => handleChange(e, i, 'pump')} value={getPumpValue('nominal_flow_rate_l_per_s', i)} error={getPumpError('nominal_flow_rate_l_per_s', name)} />
                <InputWithLabel name={'nominal_head_m'} label={`${t('nominal_head_height')} (m)`} onChange={e => handleChange(e, i, 'pump')} value={getPumpValue('nominal_head_m', i)} error={getPumpError('nominal_head_m', name)} />
                <InputWithLabel name={'curve_speed_rpm'} label={`${t('nominal_rotational_speed')} (rpm)`} onChange={e => handleChange(e, i, 'pump')} value={getPumpValue('curve_speed_rpm', i)} error={getPumpError('curve_speed_rpm', name)} />
              </div>
            )}
            <div className={styles.motor}>
              <h3>{t('motor_technical_information')}</h3>
              <InputWithLabel name={'model_name'} label={t('brand_and_model')} onChange={e => handleChange(e, i, 'motor')} value={getMotorValue('model_name', i)} />
              <InputWithLabel name={'nominal_power_watts'} label={`${t('nominal_power')} (kW)`} onChange={e => handleChange(e, i, 'motor')} value={getMotorValue('nominal_power_watts', i)} error={getMotorError('nominal_power_watts', name)} />
              <InputWithLabel name={'nominal_voltage_volts'} label={`${t('nominal_voltage')} (V)`} onChange={e => handleChange(e, i, 'motor')} value={getMotorValue('nominal_voltage_volts', i)} error={getMotorError('nominal_voltage_volts', name)} />
              <InputWithLabel name={'nominal_current_amperes'} label={`${t('nominal_current')} (A)`} onChange={e => handleChange(e, i, 'motor')} value={getMotorValue('nominal_current_amperes', i)} error={getMotorError('nominal_current_amperes', name)} />
              <InputWithLabel name={'nominal_speed_rpm'} label={`${t('nominal_speed')} (rpm)`} onChange={e => handleChange(e, i, 'motor')} value={getMotorValue('nominal_speed_rpm', i)} error={getMotorError('nominal_speed_rpm', name)} />
              <InputWithLabel name={'nominal_power_factor'} label={`${t('power_factor')}, cos φ (${t('no_unit')})`} onChange={e => handleChange(e, i, 'motor')} value={getMotorValue('nominal_power_factor', i)} error={getMotorError('nominal_power_factor', name)} />
            </div>
          </div>
          <div className={styles.measurementScaling}>
            <h3>{t('measurement_scaling')}</h3>
            <InputWithLabel name={'CT'} label={t('ct_end_value', { count: count })} info={input ? formatInputValue(input, type) : undefined} onChange={e => handleChange(e, i, 'common')} value={getValue('CT', i)} error={getError('CT', name)} required />
          </div>
        </div>
      </SectionBox>
    )
  })

  const showAddMoreEquipmentButtons = () => {
    if (installationData) {
      if (installationData.pumpCount === 0 && formData.length < 2) {
        return true;
      }
      return false;
    } else {
      if (formData.length < 2) {
        return true;
      }
      return false;
    }
  }

  return (
    <div className={styles.machinesContainer}>
      { pumps }
      { showAddMoreEquipmentButtons() &&
        <div className={styles.buttonsContainer}>
          <AddButton
            onClick={handlePumpAdd}
            data-tooltip-id='main-tooltip'
            data-tooltip-content={t('new_pump')}
          />
          <CopyButton
            onClick={handlePumpCopy}
            data-tooltip-id='main-tooltip'
            data-tooltip-content={t('copy_pump')}
          />
        </div>
      }
    </div>
  )
}

const SurfaceLevelForm = ({ handleFormChange, handleSurfaceLevelToggle, formData, errors, deviceData }: SurfaceLevelFormProps) => {
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange('equipment', e.target.name, e.target.value);
  }

  const getValue = (name: keyof EquipmentFormData) => {
    return (formData && formData[name]) || '';
  }

  const getError = (name: string) => {
    let error = errors.find(err => err[name]);
    return error ? error[name] : '';
  }

  const hasSurfaceLevel = formData['surface_level']

  const input = deviceData && deviceData.inputs.find(item => item.input === 'CL1');
  const type = (deviceData && deviceData.type) || '';

  return (
    <div className={styles.surfaceContainer}>
      <SectionBox marginTop>
        <form>
          <div className={styles.checkboxContainer}>
            { handleSurfaceLevelToggle &&
              <>
                <Checkbox name={'surface_level'} checked={hasSurfaceLevel} onChange={() => handleSurfaceLevelToggle(!hasSurfaceLevel)} />
                <h2>{t('surface_level')}</h2>
              </>
            }
          </div>
          <Collapsible open={hasSurfaceLevel}>
            <>
              <h3>{t('measurement_scaling')}</h3>
              <InputWithLabel name={'CL1'} label={t('cl1_end_value')} info={input ? formatInputValue(input, type) : undefined} onChange={handleChange} value={getValue('CL1') as string} error={getError('CL1')} required />
            </>
          </Collapsible>
        </form>
      </SectionBox>
    </div>
  )
}

const Wizard = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { setNotification } = useNotification();
  const { scrollToTop } = useScroll();
  const { publicId } = useParams();
  const { user } = useAuth();

  const wizardConfigs: Config = {
    'new-fleet': {
      startStep: 0,
      onSubmit: (formData: FormData) => API.addNewFleet(formData),
      type: 'fleet'
    },
    'new-installation': {
      startStep: 1,
      onSubmit: (formData: FormData) => API.addNewStation(formData),
      type: 'station'
    },
    'new-pump': {
      startStep: 2,
      onSubmit: (formData: FormData) => API.addNewEquipment(formData),
      type: 'pump'
    },
    'new-surface-level': {
      startStep: 2,
      onSubmit: (formData: FormData) => API.addNewEquipment(formData),
      type: 'surfaceLevel'
    },
  }

  const locationSplit = location.pathname.split('/');
  const config = wizardConfigs[locationSplit[locationSplit.length - 1]];
  const { type, startStep, onSubmit } = config;

  const organizationOptions = useMemo(() => {
    const result: any = [];
    if (user) {
      user.memberships.forEach(m => {
        if (m.role === 'edit') {
          result.push({ value: m.publicId, label: m.name });
        }
      })
    }
    return result;
  }, [user]);

  const formDataInitial: FormData = useMemo(() => {
    return {
      fleetPublicId: publicId,
      fleet: {
        organizationPublicId: organizationOptions.length === 1 ? organizationOptions[0].value : undefined
      },
      installation: {},
      equipment: {
        machines: type !== 'surfaceLevel' ? [
          {
            name: localizedEquipmentName(t, 'motor+pump', 1),
            CT: 100,
            equipmentType: 'motor+pump',
            pump: {},
            motor: {}
          }
        ] : [],
        surface_level: type === 'surfaceLevel',
        CL1: 5,
      }
    }
  }, [publicId, organizationOptions, type, t])

  const [step, setStep] = useState<number>(startStep);
  const [formData, setFormData] = useState<FormData>(() => structuredClone(formDataInitial));
  const [errors, setErrors] = useState<Errors>({
    fleet: [],
    installation: [],
    equipment: [],
    machines: []
  });
  const [title, setTitle] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [installationData, setInstallationData] = useState<InstallationData | null>(null);
  const [fleetData, setFleetData] = useState<any>(null);
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);

  useEffect(() => {
    if (publicId) {
      if (type === 'station') {
        API.getFleetById(publicId).then(data => {
          if (!hasEditPermissionForOrg(data.fleet.organizationPublicId, user) || data.fleet.devices.length > 0 || data.fleet.equipment.length > 0) {
            navigate('/fleet');
          }
          setFleetData(data.fleet);
          setTitle(`${data.fleet.name} - ${t('new_installation')}`);
        }).catch(() => {
          navigate('/fleet');
        }).finally(() => {
          setLoading(false);
        })
      } else if (type === 'pump') {
        API.getStationEquipmentById(publicId).then(data => {
          if (!hasEditPermissionForOrg(data.organizationPublicId, user) || data.pumpCount > 1) {
            navigate('/fleet');
          } else if (data.latestDevice) {
            const newFormData: FormData = structuredClone(formDataInitial);
            newFormData.equipment = {
              ...newFormData['equipment'],
              serial: data.latestDevice.serial,
              from: data.latestDevice.installTime,
              machines: [
                {
                  ...newFormData['equipment']['machines'][0],
                  name: `Pumppu ${data.pumpCount+1}`,
                }
              ]
            }
            const installationData = {
              hasSurfaceLevel: data.surfaceLevel,
              pumpCount: data.pumpCount
            }
            setFormData(newFormData);
            setInstallationData(installationData);
          }
          const fleetNames = data.partOfFleets.map((item: any) => `${item.name} - `).join('');
          setTitle(fleetNames + t('new_pump'));
        }).catch(() => {
          navigate('/fleet');
        }).finally(() => {
          setLoading(false);
        })
      } else if (type === 'surfaceLevel') {
        API.getStationEquipmentById(publicId).then(data => {
          if (!hasEditPermissionForOrg(data.organizationPublicId, user) || data.surfaceLevel) {
            navigate('/fleet');
          } else if (data.latestDevice) {
            const newFormData: FormData = structuredClone(formDataInitial);
            newFormData.equipment = {
              ...newFormData['equipment'],
              serial: data.latestDevice.serial,
              from: data.latestDevice.installTime
            }
            const installationData = {
              hasSurfaceLevel: false,
              pumpCount: data.pumpCount
            }
            setInstallationData(installationData);
            setFormData(newFormData);
          }
          const fleetNames = data.partOfFleets.map((item: any) => `${item.name} - `).join('');
          setTitle(fleetNames + t("add_surface_level"));
        }).catch(() => {
          navigate('/fleet');
        }).finally(() => {
          setLoading(false);
        })
      }
    } else {
      if (user && !user.memberships.find(m => m.role === 'edit')) {
        navigate('/fleet');
      }
      setTitle(t('new_fleet'));
      setLoading(false);
    }
  }, [type, formDataInitial, publicId, t, navigate, user])

  const serial = formData['equipment']['serial'];

  useEffect(() => {
    if (serial) {
      API.getDevice(serial).then(data => {
        setDeviceData(data)
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [serial])

  const handleFormChange = (formName: FormName, inputName: string, value: string) => {
    setFormData({
      ...formData,
      [formName]: {
        ...formData[formName],
        [inputName]: value
      }
    });
    let newErrors = errors[formName];
    newErrors = newErrors.filter(err => !Object.keys(err).includes(inputName));
    setErrors({...errors, [formName]: newErrors});
  }

  const handlePumpFormChange = (index: number, inputName: string, value: string, type: 'pump' | 'motor' | 'common') => {
    const val = value.replace(/,/g, '.');
    const newFormData = {...formData}
    const newMachines = newFormData.equipment.machines.map((pump, i: number) => {
      if (i === index) {
        if (type === 'common') {
          return {...pump, [inputName]: val };
        } else {
          return {...pump, [type]: { ...pump[type], [inputName]: val }};
        }
      }
      return pump;
    });
    newFormData.equipment.machines = newMachines;
    
    const machine = newFormData.equipment.machines[index].name;
    const newErrors: any = [...errors.machines];
    const pumpErrorIndex = newErrors.findIndex((err: any) => err[machine]);
    if (pumpErrorIndex > -1) {
      if (type === 'common' && newErrors[pumpErrorIndex] && newErrors[pumpErrorIndex][machine] && newErrors[pumpErrorIndex][machine][inputName]) {
        delete newErrors[pumpErrorIndex][machine][inputName];
      } else if (newErrors[pumpErrorIndex] && newErrors[pumpErrorIndex][machine] && newErrors[pumpErrorIndex][machine][type] && newErrors[pumpErrorIndex][machine][type][inputName]) {
        delete newErrors[pumpErrorIndex][machine][type][inputName];
      }
    }
    setFormData(newFormData);
    setErrors({...errors, machines: newErrors});
  }

  const handleLocationChange = (formName: FormName, coords: Coordinates | null) => {
    setFormData({
      ...formData,
      [formName]: {
        ...formData[formName],
        coordinates: coords ? [coords.lat, coords.lng] : null
      }
    });
  }

  const validateForm = async (schema: yup.ObjectSchema<Record<string, any>>, form: any) => {
    const newErrors: FormError[] = [];
    const machineErrors: any[] = [];
    try {
      await schema.validate(form, {abortEarly: false});
    } catch (err: any) {
      err.inner.forEach((item: any) => {
        const errorsSplit: string[] | undefined = item.path?.split('.');
        if (errorsSplit && errorsSplit.length > 1) {
          const index = parseInt(errorsSplit[0].split('[')[1].split(']')[0]);
          const machine = formData.equipment.machines[index].name;
          const type = errorsSplit[1];
          const name = errorsSplit[2];
          const secondSplit = errorsSplit.slice(1);
          let errorObject: Record<string, Record<string, string>> = {};
          secondSplit.reduce((o: Record<string, any>, s: string, i: number) => {
            if (i === secondSplit.length - 1) {
              return o[s] = item.errors[0]; 
            } 
            return o[s] = {};        
          }, errorObject);
          const machineErrorIndex = machineErrors.findIndex(err => err[machine]);
          if (machineErrorIndex > -1) {
            machineErrors[machineErrorIndex][machine] = {
              ...machineErrors[machineErrorIndex][machine],
              [type]: {
                ...machineErrors[machineErrorIndex][machine][type],
                [name]: item.errors[0]
              }
            };
          } else {
            machineErrors.push({[machine]: errorObject});
          }
        } else {
          newErrors.push({
            [item.path as string]: item.errors[0]
          });
        }
      }
    )}
    return {
      errors: newErrors,
      machineErrors: machineErrors
    }
  }
    
  const handleSubmit = (formData: FormData) => {
    onSubmit(formData).then(() => {
      setLoadingSubmit(true);
      if (type === 'fleet') {
        navigate('/fleet');
        setNotification(`${t('new_fleet_added')}: ${formData['fleet']['name']}`);
      } else if (type === 'station') {
        navigate('/fleet');
        setNotification(t('new_installation_added'));
      } else if (type === 'pump') {
        navigate(`/station/${publicId}`);
        setNotification(t('new_pump_added'));
      } else if (type === 'surfaceLevel') {
        navigate(`/station/${publicId}`);
        setNotification(t('surface_level_added'));
      }
    }).catch(() => {
      setNotification(t('error_submit'), 'DANGER');
      setLoadingSubmit(false);
    })
  }

  const handleCancel = () => {
    if (type === 'fleet') {
      navigate('/fleet');
    } else if (type === 'station') {
      navigate('/fleet', {state: { fleetId: publicId }});
    } else {
      navigate(`/station/${publicId}`);
    }
  }

  const handleValidate = async (schema: yup.ObjectSchema<Record<string, any>>, formName: FormName) => {
    const newErrors = await validateForm(schema, formData[formName]);
    if (newErrors.errors.length > 0 || newErrors.machineErrors.length > 0) {
      setNotification(t('required_information_missing'), 'WARNING');
      scrollToTop();
      setErrors({
        ...errors,
        [formName]: newErrors.errors,
        machines: newErrors.machineErrors
      });
    } else {
      handleNextStep()
    }
  }

  const handleSaveUnfinished = async (schema: yup.ObjectSchema<Record<string, any>>, formName: FormName) => {
    const newErrors = await validateForm(schema, formData[formName]);
    if (newErrors.errors.length > 0) {
      setNotification(t('required_information_missing'), 'WARNING');
      scrollToTop();
      setErrors({
        ...errors,
        [formName]: newErrors.errors
      });
    } else {
      const newFormData = {...formData};
      newFormData.equipment = {} as EquipmentFormData;
      if (formName === 'fleet') {
        newFormData.installation = {};
      }
      handleSubmit(newFormData);
    }
  }

  const handleNextStep = () => {
    if (step === 2) {
      handleSubmit(formData);
    } else {
      setStep(step+1);
      scrollToTop();
      setCurrentTitle(formData);
    }
  }

  const handlePreviousStep = () => {
    setStep(step-1);
    scrollToTop();
  }

  const handleSkipInstallation = () => {
    const newFormData = {...formData};
    newFormData.installation = {};
    setFormData(newFormData);
    setStep(step+1);
    scrollToTop();
    setCurrentTitle(newFormData);
  }

  const handlePumpAdd = () => {
    const newFormData = {...formData};
    const count = newFormData.equipment.machines.length+1;
    newFormData.equipment.machines.push({
      ...structuredClone(formDataInitial.equipment.machines[0]),
      name: localizedEquipmentName(t, 'motor+pump', count),
    });
    setFormData(newFormData);
  }

  const handlePumpCopy = () => {
    const newFormData = {...formData};
    const count = newFormData.equipment.machines.length+1;
    newFormData.equipment.machines.push({
      ...newFormData.equipment.machines[0],
      name: localizedEquipmentName(t, newFormData.equipment.machines[0].equipmentType, count),
    });
    setFormData(newFormData);
  }

  const handlePumpRemove = (index: number) => {
    const newFormData = {...formData};
    newFormData.equipment.machines.splice(index, 1);
    setFormData(newFormData);
  }

  const handleSurfaceLevelToggle = (value: boolean) => {
    const newFormData = {...formData};
    newFormData.equipment = {
      ...newFormData.equipment,
      surface_level: value,
      CL1: 5,
    }
    let newErrors = [...errors['equipment']];
    newErrors = newErrors.filter(err => !Object.keys(err).includes('CL1'));
    setErrors({...errors, equipment: newErrors});
    setFormData(newFormData);
  }

  const setCurrentTitle = (formData: FormData) => {
    const installationName = formData['installation']['name'];
    let fleetName: string | undefined = '';
    if (type === 'fleet') {
      fleetName = formData['fleet']['name'];
    } else if (type === 'station') {
      fleetName = fleetData.name;
    }
    const title = installationName ? `${fleetName} - ${installationName}` : `${fleetName}`;
    setTitle(title);
  }

  const showSurfaceLevel = () => {
    if (type === 'pump') {
      if (!installationData || (installationData && installationData.hasSurfaceLevel === false)) {
        return true;
      }
      return false;
    } else if (type === 'surfaceLevel') {
      return true;
    } else {
      return true;
    }
  }

  const showSubfleetSkip = () => {
    if (type === 'fleet') {
      return true;
    } else if (fleetData && fleetData.children.length === 0) {
      return true;
    }
    return false;
  }
  
  const currentComponent = () => {
    switch(step) {
      case 0:
        return (
          <>
            <FleetForm 
              formData={formData['fleet']}
              errors={errors['fleet']}
              handleFormChange={(inputName, value) => handleFormChange('fleet', inputName, value)}
              handleLocationChange={(coords) => handleLocationChange('fleet', coords)}
              handleLocationReset={() => handleLocationChange('fleet', null)}
              title={t('fleet_information')}
              organizations={organizationOptions.length > 1 ? organizationOptions : null}
            />
            <div className={styles.buttonsContainer}>
              <Button onClick={handleCancel} text={t('cancel')} disabled={loadingSubmit} type={'DANGER'} />
              <Button onClick={() => handleSaveUnfinished(fleetOrganizationSchema, 'fleet')} text={t('save_unfinished')} disabled={loadingSubmit} />
              <ArrowButton onClick={() => handleValidate(fleetOrganizationSchema, 'fleet')} type={'right'} />
            </div>
          </>
        )
      case 1:
        return (
          <>
            <FleetForm
              formData={formData['installation']}
              errors={errors['installation']}
              handleFormChange={(inputName, value) => handleFormChange('installation', inputName, value)}
              handleLocationChange={(coords) => handleLocationChange('installation', coords)}
              handleLocationReset={() => handleLocationChange('installation', null)}
              title={showSubfleetSkip() ? (
                <>
                  {t('station_information')}
                  <span data-tooltip-id='main-tooltip' data-tooltip-content={t('skip_info')}>
                    <InfoIcon />
                  </span>
                </> ): t('station_information') }
            />
            <div className={styles.buttonsContainer}>
              { startStep !== 1 && <ArrowButton onClick={handlePreviousStep} type={'left'} /> }
              { startStep === 1 && <Button onClick={handleCancel} text={t('cancel')} disabled={loadingSubmit} type={'DANGER'} /> }
              <Button onClick={() => handleSaveUnfinished(fleetSchema, 'installation')} text={t('save_unfinished')} disabled={loadingSubmit} />
              { showSubfleetSkip() && <Button onClick={handleSkipInstallation} text={t('skip')} disabled={loadingSubmit} /> }
              <ArrowButton onClick={() => handleValidate(fleetSchema, 'installation')} type={'right'} />
            </div>
          </>
        )
      case 2:
        return (
          <div>
            <div className={styles.equipmentContainer}>
              <EquipmentForm handleFormChange={handleFormChange} formData={formData['equipment']} errors={errors['equipment']} withInstallation={Boolean(installationData)} />
              { deviceData && <div className={styles.deviceInputsContainer}><SectionBox noOverflow><div style={{margin: 20}}><DeviceInputsTable data={deviceData} filter={'analog'} /></div></SectionBox></div> }
            </div>
            { type !== 'surfaceLevel' &&
              <MotorPumpForm handleFormChange={handlePumpFormChange} formData={formData['equipment'] && formData['equipment']['machines']} errors={errors['machines']} handlePumpAdd={handlePumpAdd} handlePumpCopy={handlePumpCopy} handlePumpRemove={handlePumpRemove} installationData={installationData} deviceData={deviceData} />
            }
            { showSurfaceLevel() &&
              <SurfaceLevelForm handleFormChange={handleFormChange} handleSurfaceLevelToggle={type !== 'surfaceLevel' ? handleSurfaceLevelToggle : undefined} formData={formData['equipment']} errors={errors['equipment']} deviceData={deviceData} />
            }
            <div className={styles.buttonsContainer} style={{maxWidth: 1200}}>
              { startStep !== 2 && <ArrowButton onClick={handlePreviousStep} type={'left'} /> }
              { startStep === 2 && <Button onClick={handleCancel} text={t('cancel')} disabled={loadingSubmit} type={'DANGER'} /> }
              <Button onClick={() => handleValidate(equipmentSchema, 'equipment')} text={t('submit')} loading={loadingSubmit} />
            </div>
          </div>
        )
    }
  }

  return (
    <div className={styles.wizard}>
      { loading && <PageLoader /> }
      { title !== null && (<>
        <Title title={title} />
        <h1>{ title }</h1>
      </>)}
      { currentComponent() }
    </div>
  )
}

export default Wizard